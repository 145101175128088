import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { SectionFive } from "./SectionFive";
import { SectionFiveHeader } from "./SectionFive/header";
import { SectionFour } from "./SectionFour";
import { SectionFourHeader } from "./SectionFour/header";
import { SectionOne } from "./SectionOne";
import { SectionOneHeader } from "./SectionOne/header";
import { SectionSix } from "./SectionSix";
import { SectionSixHeader } from "./SectionSix/header";
import { SectionThree } from "./SectionThree";
import { SectionThreeHeader } from "./SectionThree/header";
import { SectionTwo } from "./SectionTwo";
import { SectionTwoHeader } from "./SectionTwo/header";

export function MedicalFormUniversalPresenter({
  onChange,
}: {
  onChange: (value: any, statePath: string) => void;
}) {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <SectionOneHeader />
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwoHeader />
        <SectionTwo onChange={onChange} />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThreeHeader />
        <SectionThree />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFourHeader />
        <SectionFour />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFiveHeader />
        <SectionFive />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSixHeader />
        <SectionSix />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
