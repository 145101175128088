import Translations from "translations/types";

export const SHARE_PATIENT_DATE_KEY = "share_patient_data" as const;

const SHARE_PATIENT_DATE_STEPS = {
  KEEP_OVERVIEW: "keep_overview",
  GRANT_ACCESS: "grant_access",
  TRACK_CHANGES: "track_changes",
} as const;

export const ACCESS_TO_PATIENT_DATE = {
  key: SHARE_PATIENT_DATE_KEY,
  steps: {
    [SHARE_PATIENT_DATE_STEPS.KEEP_OVERVIEW]: {
      key: SHARE_PATIENT_DATE_STEPS.KEEP_OVERVIEW,
      intro: (t: Translations) => t.tours.accessPatientData.stepA.text,
      title: (t: Translations) => t.tours.accessPatientData.stepA.title,
      step: 1,
    },
    [SHARE_PATIENT_DATE_STEPS.GRANT_ACCESS]: {
      key: SHARE_PATIENT_DATE_STEPS.GRANT_ACCESS,
      intro: (t: Translations) => t.tours.accessPatientData.stepB.text,
      title: (t: Translations) => t.tours.accessPatientData.stepB.title,
      step: 2,
    },
    [SHARE_PATIENT_DATE_STEPS.TRACK_CHANGES]: {
      key: SHARE_PATIENT_DATE_STEPS.TRACK_CHANGES,
      intro: (t: Translations) => t.tours.accessPatientData.stepC.text,
      title: (t: Translations) => t.tours.accessPatientData.stepC.title,
      step: 3,
      position: "left",
    },
  },
} as const;
