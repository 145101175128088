import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { margin, padding } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { TextAreaWithLabel } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const RehabGoals = () => {
  const translations = useTranslations();

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          padding: padding(0, 0, 1),
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.medicalForm.sectionFour.rehabGoals}
      </Subheading>
      <TextAreaWithLabel
        elementName="rehabilitation_goals"
        label={translations.patientForms.medicalForm.sectionFour.rehabGoals}
        formLabelSx={visuallyHidden as SxProps}
      />
    </>
  );
};
