import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionNine = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionEight.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {
          translations.patientForms.drvGeneralForm.sectionEight
            .contributionsPension
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="contributions_to_statutory_pension"
        label={
          translations.patientForms.drvGeneralForm.sectionEight
            .contributionsPension
        }
        formLabelSx={visuallyHidden}
        startWithNo
      />
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.drvGeneralForm.sectionEight
            .contributionsAbroad
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="contributions_to_foreign_insurance_info"
        label={
          translations.patientForms.drvGeneralForm.sectionEight
            .contributionsAbroad
        }
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "contributions_to_foreign_insurance_info_state");
          mutate(null, "contributions_to_foreign_insurance_info_from_to");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout gap={sizing(1)} padding={padding(0, 0, 1)}>
              <TextInputWithLabel
                elementName="contributions_to_foreign_insurance_info_state"
                flatModel
                label={
                  translations.patientForms.drvGeneralForm.sectionEight
                    .contributionsState
                }
                large
                marginOverride={margin(0)}
              />
              <TextInputWithLabel
                elementName="contributions_to_foreign_insurance_info_from_to"
                flatModel
                label={
                  translations.patientForms.drvGeneralForm.sectionEight.timeline
                }
                large
                marginOverride={margin(0)}
              />
            </VerticalLayout>
          ),
        }}
      />
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.drvGeneralForm.sectionEight
            .currentContributionsAbroad
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="contributions_to_foreign_insurance_current"
        label={
          translations.patientForms.drvGeneralForm.sectionEight
            .currentContributionsAbroad
        }
        formLabelSx={visuallyHidden}
        startWithNo
      />
    </Section>
  );
};
