import {
  DRV_TRAVEL_TYPES,
  OUTPATIENT_NOT_POSSIBLE_REASONS,
  PREDICAMENT_STATE_YES,
  REHAB_TRIGGERING_EVENT,
} from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import { ValueOf } from "core/types";
import { assertUnreachable } from "dsl/ecosystems/PatientForms/utils";
import { PDFForm } from "pdf-lib";
import { handlePdfDateField } from "../../utils";
import { MedicalFormPensionData } from "./modelDefinition";

export const medicalPensionFormMapping = ({
  form,
  formData,
  locale,
}: {
  form: PDFForm;
  formData: MedicalFormPensionData;
  locale: Locale;
}) => {
  const handleCheckboxes = <
    T extends
      | typeof OUTPATIENT_NOT_POSSIBLE_REASONS
      | typeof DRV_TRAVEL_TYPES
      | typeof REHAB_TRIGGERING_EVENT,
  >({
    formFieldName,
    possibleValues,
    value,
  }: {
    formFieldName: keyof MedicalFormPensionData;
    possibleValues: T;
    value: ValueOf<MedicalFormPensionData>;
  }) => {
    if (!value || !Array.isArray(value) || !value.length) {
      return;
    }
    Object.values(possibleValues).forEach((possibleValue) => {
      const checkbox = form.getCheckBox(`${formFieldName}_${possibleValue}`);
      if ((value as ValueOf<T>[]).includes(possibleValue as ValueOf<T>)) {
        checkbox.check();
      } else {
        checkbox.uncheck();
      }
    });
  };

  Object.entries(formData).forEach(([key, value]) => {
    try {
      const formFieldName = key as keyof MedicalFormPensionData;
      switch (formFieldName) {
        // DATE FIELDS
        case "rehab_triggering_event_date":
        case "admission_date":
        case "discharge_date":
        case "patient_birthdate":
        case "transfer_date": {
          handlePdfDateField({
            formFieldName,
            value,
            form,
            locale,
            removeSeparators: true,
          });
          break;
        }
        case "further_therapeutic_diagnostic_measures_finished_on":
        case "further_therapeutic_diagnostic_measures_from":
        case "further_therapeutic_diagnostic_measures_to": {
          handlePdfDateField({ formFieldName, value, form, locale });
          break;
        }
        // TEXT FIELDS
        case "clinical_findings":
        case "complications":
        case "current_therapy":
        case "drg_code":
        case "drv_remarks":
        case "further_therapeutic_diagnostic_measures_description":
        case "hospital_bank_holder":
        case "hospital_bank_name":
        case "hospital_billing_address":
        case "hospital_ik_number":
        case "hospital_name_address":
        case "icd_code_1_code":
        case "icd_code_2_code":
        case "icd_code_3_code":
        case "infections":
        case "main_diagnosis":
        case "needs_catheter":
        case "orientation_disoriented":
        case "patient_city":
        case "insurance_delivery_address":
        case "patient_name":
        case "patient_street_housenumber":
        case "patient_zipcode":
        case "preferred_rehab_clinic":
        case "secondary_diagnosis":
        case "social_worker_name_number":
        case "statutory_pension_insurance_number": {
          if (value != null && typeof value !== "string") {
            console.warn("incorrect value type for text field", {
              formFieldName,
              value,
            });
            break;
          }

          form.getTextField(formFieldName).setText(value || "");
          break;
        }
        case "hospital_iban": {
          if (!value) break;

          if (typeof value !== "string") {
            console.warn("incorrect value type for text field", {
              formFieldName,
              value,
            });
            break;
          }

          form
            .getTextField(formFieldName)
            .setText(value.slice(2).replaceAll(" ", ""));
          break;
        }
        // NUMBER FIELDS
        case "patient_weight":
        case "patient_height":
        case "carelevel": {
          form.getTextField(formFieldName).setText(value?.toString() || "");
          break;
        }
        // CHECKBOX FIELDS
        case "day_reha":
        case "full_reha":
        case "pension_procedure_after_examination":
        case "pension_procedure_direct": {
          if (value != null && typeof value !== "boolean") {
            console.warn("incorrect value type for checkbox field", {
              formFieldName,
              value,
            });
            break;
          }

          const checkbox = form.getCheckBox(formFieldName);
          if (value) {
            checkbox.check();
          } else {
            checkbox.uncheck();
          }
          break;
        }
        case "secondary_diagnosis_selected": {
          const checkbox = form.getCheckBox(`${formFieldName}_2`);
          if (value !== PREDICAMENT_STATE_YES) {
            checkbox.check();
          } else {
            checkbox.uncheck();
          }
          break;
        }
        // RADIO FIELDS
        case "carelevel_selected":
        case "eat_without_help":
        case "fecal_incontinence":
        case "infections_selected":
        case "mobility_walk_without_help":
        case "mobility_wheel_chair":
        case "needs_catheter_selected":
        case "orientation_disoriented_selected":
        case "urinary_incontinence":
        case "wash_and_dress_without_help":
        case "wound_closed": {
          const radio = form.getRadioGroup(formFieldName);
          if (value == null) {
            radio.clear();
            return;
          }
          if (typeof value !== "number") {
            console.warn("incorrect value type for radio field", {
              formFieldName,
              value,
            });
            break;
          }

          radio.select(value.toString());
          break;
        }
        // CHECKBOX GROUP FIELDS
        case "outpatient_not_possible_reason": {
          handleCheckboxes({
            possibleValues: OUTPATIENT_NOT_POSSIBLE_REASONS,
            value,
            formFieldName,
          });
          break;
        }
        case "rehab_triggering_event": {
          handleCheckboxes({
            possibleValues: REHAB_TRIGGERING_EVENT,
            value,
            formFieldName,
          });
          break;
        }
        // not in PDF
        case "transport_type": {
          handleCheckboxes({
            possibleValues: DRV_TRAVEL_TYPES,
            value,
            formFieldName,
          });
          break;
        }
        // IGNORED
        case "other_measures":
          break;
        default: {
          assertUnreachable(formFieldName);
        }
      }
    } catch (err) {
      console.error(
        `error setting field ${key} in PDF: ${getErrorMessage(err)}`,
      );
    }
  });
};
