import { REQUEST_FORM_FIELDS } from "core/consts";
import { TransitionalCareRequest, ValueOf } from "core/types";
import {
  Dispatch,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FieldValidation } from "react-forms-state";
import { useTranslations } from "translations";
import { ACTION_TYPES, Actions, getReducer } from "./reducer";

export type FormField = ValueOf<typeof REQUEST_FORM_FIELDS>;

export type TransitionalRequestValidationType = Record<
  keyof Pick<TransitionalCareRequest, FormField>,
  FieldValidation
>;

export type RequestListContextState = {
  adding: TransitionalCareRequest | null;
  editing: TransitionalCareRequest | null;
  request_list: TransitionalCareRequest[];
  validation: TransitionalRequestValidationType;
};

type RequestListContextType = {
  dispatch: Dispatch<Actions>;
  state: RequestListContextState;
};

export const defaultRequestListContext: RequestListContextType = {
  state: {
    request_list: [],
    editing: null,
    adding: null,
    validation: {
      [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ADDRESS]: true,
      [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_CITY]: true,
      [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE]: true,
      [REQUEST_FORM_FIELDS.CAREPROVIDER_NAME]: true,
      [REQUEST_FORM_FIELDS.REQUEST_SENT_DATE]: true,
      [REQUEST_FORM_FIELDS.REQUEST_SENT_TIME]: true,
      [REQUEST_FORM_FIELDS.REQUEST_STATUS]: true,
    },
  },
  dispatch: () => {},
};

const RequestListContext = createContext<RequestListContextType>(
  defaultRequestListContext,
);

export function RequestListProvider({
  children,
  formValue,
  handleSubmit,
}: {
  children: ReactNode;
  formValue: TransitionalCareRequest[];
  handleSubmit: (value: TransitionalCareRequest[]) => void;
}) {
  const translations = useTranslations();
  const [state, dispatch] = useReducer<
    Reducer<RequestListContextState, Actions>
  >(getReducer(handleSubmit, translations), {
    ...defaultRequestListContext.state,
    request_list: formValue,
  });

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.RESET, payload: { requests: formValue } });
  }, [formValue]);

  return (
    <RequestListContext.Provider value={{ state, dispatch }}>
      {children}
    </RequestListContext.Provider>
  );
}

export const useRequestListContext = () => useContext(RequestListContext);
