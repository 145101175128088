import { Section } from "dsl/ecosystems/PatientForms/components";
import { EarlyRehabCriteria } from "./EarlyRehabCriteria";
import { MusculoskeletalResilience } from "./MusculoskeletalResilience";
import { Resilience } from "./Resilience";

export const SectionThree = () => {
  return (
    <Section>
      <Resilience />
      <MusculoskeletalResilience />
      <EarlyRehabCriteria />
    </Section>
  );
};
