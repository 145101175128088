import { Section } from "dsl/ecosystems/PatientForms/components";
import { RehabDirective } from "./RehabDirective";
import { RehabFocus } from "./RehabFocus";
import { RehabType } from "./RehabType";

export const SectionFive = () => {
  return (
    <Section>
      <RehabFocus />
      <RehabType />
      <RehabDirective />
    </Section>
  );
};
