import { Section } from "dsl/ecosystems/PatientForms/components";
import { HospitalContactPerson } from "./HospitalContactPerson";
import { HospitalInformation } from "./HospitalInformation";
import { PatientCommunication } from "./PatientCommunication";
import { CostCoverage } from "./CostCoverage";

export const SectionTwo = () => {
  return (
    <Section>
      <HospitalInformation />
      <HospitalContactPerson />
      <PatientCommunication />
      <CostCoverage />
    </Section>
  );
};
