import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionZero = () => {
  const translations = useTranslations();

  return (
    <Section>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.insuredPerson.name}
          large
          elementName="patient_name"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.insuredPerson.street}
          large
          elementName="patient_street_housenumber"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.insuredPerson.city}
          large
          elementName="patient_city_zipcode"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.insuredPerson.phone}
          large
          elementName="patient_phone_number"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.insuredPerson.healthInsurance
          }
          large
          elementName="insurance_company_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.insuredPerson.insuranceNumber
          }
          elementName="insurance_number"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.generalForm.insuredPerson.dateOfBirth
          }
          elementName="patient_birthdate"
        />
      </SectionRow>
    </Section>
  );
};
