import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Section,
  SectionRow,
  TextAreaWithLabel,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionFour = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvMedicalForm.sectionFour.title}>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextAreaWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.descriptionHistory}
          elementName="clinical_findings"
          marginOverride={margin(0)}
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionFour.weight}
          elementName="patient_weight"
          endAdornment={translations.abbreviations.kilogram.abbreviation}
          textInputType="number"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionFour.height}
          endAdornment={translations.abbreviations.centimeter.abbreviation}
          elementName="patient_height"
          textInputType="number"
        />
      </SectionRow>
    </Section>
  );
};
