import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { SectionEight } from "./FormSections/SectionEight";
import { SectionEleven } from "./FormSections/SectionEleven";
import { SectionFive } from "./FormSections/SectionFive";
import { SectionFour } from "./FormSections/SectionFour";
import { SectionFourteen } from "./FormSections/SectionFourteen";
import { SectionNine } from "./FormSections/SectionNine";
import { SectionOne } from "./FormSections/SectionOne";
import { SectionSeven } from "./FormSections/SectionSeven";
import { SectionSix } from "./FormSections/SectionSix";
import { SectionTen } from "./FormSections/SectionTen";
import { SectionThirteen } from "./FormSections/SectionThirteen";
import { SectionThree } from "./FormSections/SectionThree";
import { SectionTwelve } from "./FormSections/SectionTwelve";
import { SectionTwo } from "./FormSections/SectionTwo";
import { SectionZero } from "./FormSections/SectionZero";

export function GeneralFormPensionPresenter() {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <SectionZero />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwo />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThree />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFour />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFive />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSix />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSeven />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionEight />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionNine />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTen />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionEleven />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwelve />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThirteen />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFourteen />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
