import { OntologyType } from "core/model/utils/ontologies";
import { useGetOntologies } from "core/model/utils/ontologies/hooks";
import { getName } from "core/model/utils/ontologies/utils";
import Select, { SelectConnectedWithForm } from "ds_legacy/components/Select";
import { SelectType } from "ds_legacy/components/Select/types";
import { useTranslations } from "translations";

function useFilteredOptions({
  exclude,
  include,
  type,
}: {
  exclude?: number[] | null;
  include?: number[] | null;
  type: OntologyType;
}) {
  const getOntologies = useGetOntologies();
  let values = getOntologies({ type, sort: true });

  if (include) {
    values = values.filter((v) => include?.includes(v.value));
  }

  if (exclude) {
    values = values.filter((v) => exclude && !exclude.includes(v.value));
  }
  return values.map(({ name: label, value }) => ({
    label,
    value,
    id: value,
  }));
}

function getSelectValue({
  multiple,
  options,
  value,
}: {
  multiple?: boolean;
  options: SelectType["options"];
  value?: SelectType["value"];
}) {
  return multiple && Array.isArray(value)
    ? value.map((ontVal) => options.find((option) => option.value === ontVal))
    : options.find((option) => option.value === value);
}

type SelectForOntologyProps = Partial<SelectType> & {
  exclude?: number[] | null;
  include?: number[] | null;
  noLabel?: boolean;
  type: OntologyType;
};

type ConnectedSelectForOntology = SelectForOntologyProps & {
  connected: true;
  elementName: string;
};

type DisconnectedSelectForOntology = SelectForOntologyProps & {
  connected?: false;
  elementName?: never;
};

// Type Overloads
export function SelectForOntology(
  props: ConnectedSelectForOntology,
): JSX.Element;
export function SelectForOntology(
  props: DisconnectedSelectForOntology,
): JSX.Element;

export default function SelectForOntology({
  connected = false,
  elementName,
  exclude,
  include,
  label,
  multiple,
  noLabel,
  type,
  value,
}: ConnectedSelectForOntology | DisconnectedSelectForOntology) {
  const translations = useTranslations();
  const options = useFilteredOptions({ type, include, exclude });

  const selectLabel = noLabel
    ? undefined
    : label || getName(type, translations);

  const selectValue = getSelectValue({ value, multiple, options });

  const selectProps = {
    connected,
    exclude,
    include,
    multiple,
    noLabel,
    type,
    label: selectLabel,
    options,
    value: selectValue,
  };

  if (connected) {
    return (
      <SelectConnectedWithForm {...selectProps} elementName={elementName} />
    );
  }

  return <Select {...selectProps} />;
}
