import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { MUSCOSKELETAL_RESILIENCE } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const MusculoskeletalResilience = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: MUSCOSKELETAL_RESILIENCE.FULLY,
      value: MUSCOSKELETAL_RESILIENCE.FULLY,
      label: translations.patientForms.medicalForm.sectionThree.fully,
    },
    {
      id: MUSCOSKELETAL_RESILIENCE.PARTIALLY,
      value: MUSCOSKELETAL_RESILIENCE.PARTIALLY,
      label: translations.patientForms.medicalForm.sectionThree.partially,
      subForm: (
        <TextInputWithLabel
          elementName="reha_capacity_partial"
          flatModel
          endAdornment={translations.abbreviations.kilogram.abbreviation}
          marginOverride={margin(0, 0, 1, 4.5)}
        />
      ),
    },
    {
      id: MUSCOSKELETAL_RESILIENCE.STABLE,
      value: MUSCOSKELETAL_RESILIENCE.STABLE,
      label: translations.patientForms.medicalForm.sectionThree.stable,
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.medicalForm.sectionThree
            .musculoskeletalSystem
        }
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="reha_capacity"
        label={
          translations.patientForms.medicalForm.sectionThree
            .musculoskeletalSystem
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(value, mutate) => {
          if (value !== MUSCOSKELETAL_RESILIENCE.PARTIALLY) {
            return mutate(null, "reha_capacity_partial");
          }
        }}
      />
      <YesNoRadioWithLabel
        elementName="interim_prosthesis_fitted"
        label={translations.patientForms.medicalForm.sectionThree.prosthesis}
        startWithNo
      />
      <TextAreaWithLabel
        bold
        elementName="reha_capacity_other"
        label={translations.patientForms.medicalForm.sectionThree.specifics}
        marginOverride={margin(0)}
      />
    </>
  );
};
