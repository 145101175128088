import { VISUALLY_IMPAIRED_DOCUMENT } from "core/consts";
import ConnectedCheckboxGroup, {
  CheckboxGroupProps,
} from "ds_legacy/components/CheckboxGroup";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_14 } from "ds_legacy/materials/typography";
import {
  LARGE_INPUT_WIDTH,
  PatientFormSubheading,
  Section,
  SMALL_INPUT_MIN_WIDTH,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import {
  getActivableStyles,
  SEND_DOCUMENTS_CHARACTER_LIMIT,
} from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { FormWatcher } from "react-forms-state/src";
import { useTranslations } from "translations";

export const SectionFourteen = () => {
  const translations = useTranslations();

  const options: CheckboxGroupProps["items"] = [
    {
      id: VISUALLY_IMPAIRED_DOCUMENT.LARGE_PRINT,
      label:
        translations.patientForms.drvGeneralForm.sectionThirteen.largePrint,
    },
    {
      id: VISUALLY_IMPAIRED_DOCUMENT.BRAILLE_SHORT,
      label:
        translations.patientForms.drvGeneralForm.sectionThirteen.brailleShort,
    },
    {
      id: VISUALLY_IMPAIRED_DOCUMENT.BRAILLE_LONG,
      label:
        translations.patientForms.drvGeneralForm.sectionThirteen.brailleFull,
    },
    {
      id: VISUALLY_IMPAIRED_DOCUMENT.CD,
      label: translations.patientForms.drvGeneralForm.sectionThirteen.cd,
    },
    {
      id: VISUALLY_IMPAIRED_DOCUMENT.AUDIO,
      label: translations.patientForms.drvGeneralForm.sectionThirteen.audio,
    },
  ];

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionThirteen.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionThirteen.subtitleEmail}
      </PatientFormSubheading>
      <Body as="p" fontSize={FONT_SIZE_14} margin={margin(0, 0, 2)}>
        {translations.patientForms.drvGeneralForm.sectionThirteen.description}
      </Body>
      <CheckboxInputField
        elementName="send_documents_per_email"
        label={
          translations.patientForms.drvGeneralForm.sectionThirteen.confirmation
        }
        sideMutation={(_value, mutate) => {
          mutate(null, "send_documents_per_email_description");
        }}
      />
      <FormWatcher watchPath="send_documents_per_email">
        {({ watchedValue }) => {
          return (
            <TextInputWithLabel
              elementName="send_documents_per_email_description"
              flatModel
              characterLimit={SEND_DOCUMENTS_CHARACTER_LIMIT}
              label={
                translations.patientForms.drvGeneralForm.sectionThirteen
                  .germanMailAddress
              }
              marginOverride={margin(0)}
              labelWrapperSx={{
                width: LARGE_INPUT_WIDTH,
                margin: margin(1, 0),
                ...getActivableStyles(watchedValue),
              }}
              inputProps={{
                style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
              }}
            />
          );
        }}
      </FormWatcher>
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.drvGeneralForm.sectionThirteen
            .visuallyImpaired
        }
      </PatientFormSubheading>
      <ConnectedCheckboxGroup
        elementName="visually_impaired_document"
        formGroupStyle={{ gap: sizing(1) }}
        label={
          translations.patientForms.drvGeneralForm.sectionThirteen
            .additionalFormat
        }
        labelStyle={{ fontSize: FONT_SIZE_14 }}
        items={options}
      />
    </Section>
  );
};
