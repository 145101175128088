import {
  OUTPATIENT_NOT_POSSIBLE_REASONS,
  PREDICAMENT_STATE_NO,
  REHAB_FORM_NAMES,
  REHAB_TRIGGERING_EVENT,
} from "core/consts";
import {
  booleanToPredicamentState,
  convertActivableInputToPredicamentState,
  DRG_CODE_CHARACTER_LIMIT,
  ICD_CODE_CHARACTER_LIMIT,
  IK_NUMBER_CHARACTER_LIMIT,
  INFECTIONS_CHARACTER_LIMIT,
  OldRehabFormDataMapped,
  PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
  stringDateToUnix,
} from "../../utils";
import {
  MedicalFormPensionData,
  MedicalFormPensionDataOld,
} from "./modelDefinition";

const convertRehabTriggeringEvent = (
  old: MedicalFormPensionDataOld["rehab_triggering_event"],
): MedicalFormPensionData["rehab_triggering_event"] => {
  if (!old) return null;

  return Object.entries(old)
    .map(([key, value]) => {
      const oldKey =
        key as keyof MedicalFormPensionDataOld["rehab_triggering_event"];
      switch (oldKey) {
        case "rehab_triggering_event_asr_surgery": {
          return value ? REHAB_TRIGGERING_EVENT.SURGERY : null;
        }
        case "rehab_triggering_event_last_antineoplastic_therapy": {
          return value ? REHAB_TRIGGERING_EVENT.ANTINEOPLASTIC_THERAPY : null;
        }
        case "rehab_triggering_event_last_radiation": {
          return value ? REHAB_TRIGGERING_EVENT.RADIATION : null;
        }
        case "rehab_triggering_event_nothing": {
          return value ? REHAB_TRIGGERING_EVENT.NOT_APPLICABLE : null;
        }
        case "rehab_triggering_event_other": {
          return value ? REHAB_TRIGGERING_EVENT.NOT_APPLICABLE : null;
        }
        case "rehab_triggering_event_work_accident_illness": {
          return value ? REHAB_TRIGGERING_EVENT.ACCIDENT_WORK : null;
        }
        case "rehab_triggering_event_date":
        case "rehab_triggering_event_caused_by_other": {
          break;
        }
      }
    })
    .truthy();
};

export function convertMedicalFormPensionData({
  formDataOld,
}: {
  formDataOld: OldRehabFormDataMapped<typeof REHAB_FORM_NAMES.MEDICAL_PENSION>;
}): MedicalFormPensionData {
  return {
    clinical_findings: formDataOld.clinical_findings ?? null,
    complications: formDataOld.complications ?? null,
    current_therapy: formDataOld.current_therapy ?? null,
    day_reha: formDataOld.day_reha ?? null,
    discharge_date: formDataOld.discharge_date ?? null,
    drg_code: formDataOld.drg_code?.slice(0, DRG_CODE_CHARACTER_LIMIT) ?? null,
    drv_remarks: formDataOld.drv_remarks ?? null,
    eat_without_help: booleanToPredicamentState(formDataOld.eat_without_help),
    fecal_incontinence: booleanToPredicamentState(
      formDataOld.fecal_incontinence,
    ),
    full_reha: formDataOld.full_reha ?? null,
    further_therapeutic_diagnostic_measures_description:
      formDataOld.further_therapeutic_diagnostic_measures_description ?? null,
    further_therapeutic_diagnostic_measures_finished_on:
      formDataOld.further_therapeutic_diagnostic_measures_finished_on ?? null,
    further_therapeutic_diagnostic_measures_from:
      formDataOld.further_therapeutic_diagnostic_measures_from ?? null,
    further_therapeutic_diagnostic_measures_to:
      formDataOld.further_therapeutic_diagnostic_measures_to ?? null,
    hospital_bank_holder: formDataOld.hospital_bank_holder ?? null,
    hospital_bank_name: formDataOld.hospital_bank_name ?? null,
    hospital_billing_address: formDataOld.hospital_billing_address ?? null,
    hospital_iban: formDataOld.hospital_iban ?? null,
    hospital_ik_number:
      formDataOld.hospital_ik_number?.slice(0, IK_NUMBER_CHARACTER_LIMIT) ??
      null,
    hospital_name_address: formDataOld.hospital_name_address ?? null,
    icd_code_1_code:
      formDataOld.icd_code_1_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ?? null,
    icd_code_2_code:
      formDataOld.icd_code_2_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ?? null,
    icd_code_3_code:
      formDataOld.icd_code_3_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ?? null,
    infections: formDataOld.infections
      ? formDataOld.infections.slice(0, INFECTIONS_CHARACTER_LIMIT)
      : null,
    infections_selected:
      formDataOld.infections === 0
        ? PREDICAMENT_STATE_NO
        : convertActivableInputToPredicamentState(
            formDataOld.infections ?? null,
          ),
    insurance_delivery_address: formDataOld.insurance_delivery_address ?? null,
    main_diagnosis: formDataOld.main_diagnosis ?? null,
    mobility_walk_without_help: booleanToPredicamentState(
      formDataOld.mobility_walk_without_help,
    ),
    mobility_wheel_chair: booleanToPredicamentState(
      formDataOld.mobility_wheel_chair,
    ),
    needs_catheter: formDataOld.needs_catheter || null,
    needs_catheter_selected: convertActivableInputToPredicamentState(
      formDataOld.needs_catheter === 0
        ? null
        : formDataOld.needs_catheter ?? null,
    ),
    orientation_disoriented: formDataOld.orientation_disoriented || null,
    orientation_disoriented_selected: convertActivableInputToPredicamentState(
      formDataOld.orientation_disoriented === 0
        ? null
        : formDataOld.orientation_disoriented ?? null,
    ),
    other_measures: null,
    patient_birthdate: formDataOld.patient_birthdate ?? null,
    patient_city: formDataOld.patient_city_zipcode ?? null,
    patient_zipcode: null,
    patient_height: formDataOld.patient_height ?? null,
    patient_name: formDataOld.patient_name ?? null,
    patient_street_housenumber: formDataOld.patient_street_housenumber ?? null,
    patient_weight: formDataOld.patient_weight ?? null,
    pension_procedure_after_examination:
      formDataOld.pension_procedure_after_examination ?? null,
    pension_procedure_direct: formDataOld.pension_procedure_direct ?? null,
    preferred_rehab_clinic: formDataOld.preferred_rehab_clinic ?? null,
    rehab_triggering_event: convertRehabTriggeringEvent(
      formDataOld.rehab_triggering_event ?? null,
    ),
    rehab_triggering_event_date: stringDateToUnix({
      date:
        formDataOld.rehab_triggering_event?.rehab_triggering_event_date ?? null,
    }),
    secondary_diagnosis: formDataOld.secondary_diagnosis || null,
    secondary_diagnosis_selected: convertActivableInputToPredicamentState(
      formDataOld.secondary_diagnosis === 0
        ? null
        : formDataOld.secondary_diagnosis ?? null,
    ),
    social_worker_name_number: formDataOld.social_worker_name_number ?? null,
    statutory_pension_insurance_number:
      formDataOld.statutory_pension_insurance_number?.slice(
        0,
        PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
      ) ?? null,
    transfer_date: formDataOld.transfer_date ?? null,
    transport_type: formDataOld.transport_type ?? null,
    urinary_incontinence: booleanToPredicamentState(
      formDataOld.urinary_incontinence,
    ),
    wash_and_dress_without_help: booleanToPredicamentState(
      formDataOld.wash_and_dress_without_help,
    ),
    wound_closed: booleanToPredicamentState(formDataOld.wound_closed),
    outpatient_not_possible_reason: formDataOld.require_accompanying_person
      ? [OUTPATIENT_NOT_POSSIBLE_REASONS.ACCOMPANYING_PERSON]
      : null,
  };
}
