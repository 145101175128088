import { FormControl, FormGroup, SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { SubheadingFormLabel } from "ds_legacy/components/FormComponents/SubheadingFormLabel";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { getActivableStyles } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import {
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { FormWatcher } from "react-forms-state";
import { useTranslations } from "translations";

export const SpecialRehabRequirements = () => {
  const translations = useTranslations();

  const ITEMS = [
    {
      elementName: "special_requirements_dialysis",
      label: translations.patientForms.medicalForm.sectionSix.dialysis,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_dialysis_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_chemotherapy",
      label: translations.patientForms.medicalForm.sectionSix.chemotherapy,
    },
    {
      elementName: "special_requirements_immunosuppressants",
      label:
        translations.patientForms.medicalForm.sectionSix.immunosuppressants,
    },
    {
      elementName: "special_requirements_catheter",
      label: translations.patientForms.medicalForm.sectionSix.catheter,
    },
    {
      elementName: "special_requirements_peg",
      label: translations.patientForms.medicalForm.sectionSix.peg,
    },
    {
      elementName: "special_requirements_isolation",
      label: translations.patientForms.medicalForm.sectionSix.isolation,
    },
    {
      elementName: "special_requirements_ventilation",
      label: translations.patientForms.medicalForm.sectionSix.ventilation,
    },
    {
      elementName: "special_requirements_tracheostoma",
      label: translations.patientForms.medicalForm.sectionSix.tracheostoma,
    },
    {
      elementName: "special_requirements_weaning",
      label: translations.patientForms.medicalForm.sectionSix.weaning,
    },
    {
      elementName: "special_requirements_prosthesis",
      label: translations.patientForms.medicalForm.sectionSix.prosthesis,
    },
    {
      elementName: "special_requirements_overlength_bed",
      label: translations.patientForms.medicalForm.sectionSix.overlengthBed,
    },
    {
      elementName: "special_requirements_obesity",
      label: translations.patientForms.medicalForm.sectionSix.obesity,
    },
    {
      elementName: "special_requirements_impairment",
      label: translations.patientForms.medicalForm.sectionSix.impairment,
    },
    {
      elementName: "special_requirements_weight_bed",
      label: translations.patientForms.medicalForm.sectionSix.weightBed,
      children: (
        <TextInputWithLabel
          elementName="special_requirements_weight_bed_description"
          flatModel
          endAdornment={translations.abbreviations.kilogram.abbreviation}
          marginOverride={margin(0, 0, 1, 4.5)}
        />
      ),
    },
    {
      elementName: "special_requirements_relatives",
      label: translations.patientForms.medicalForm.sectionSix.relatives,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_relatives_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_decubitus",
      label: translations.patientForms.medicalForm.sectionSix.decubitus,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_decubitus_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_other",
      label: translations.patientForms.medicalForm.sectionSix.other,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_other_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          padding: padding(0, 0, 1),
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.medicalForm.sectionSix.specialRequirements}
      </Subheading>
      <YesNoRadioWithLabel
        elementName="special_requirements_required"
        label={
          translations.patientForms.medicalForm.sectionSix.specialRequirements
        }
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          ITEMS.forEach((item) => {
            mutate(null, item.elementName);
            if (item.children?.props.elementName)
              mutate(null, item.children?.props.elementName);
          });
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <FormControl
              component="fieldset"
              sx={{ padding: padding(0, 0, 1) }}
            >
              <SubheadingFormLabel sx={visuallyHidden as SxProps}>
                {
                  translations.patientForms.medicalForm.sectionSix
                    .specialRequirementsSelectOptionsLabel
                }
              </SubheadingFormLabel>
              <FormGroup sx={{ gap: sizing(1) }}>
                {ITEMS.map((item, index) => {
                  return (
                    <VerticalLayout key={index} overflow="visible">
                      <CheckboxInputField
                        elementName={item.elementName}
                        flatModel
                        label={item.label}
                        sideMutation={(value, mutate) => {
                          if (!value) {
                            if (!item.children?.props.elementName) return;
                            return mutate(
                              null,
                              item.children.props.elementName,
                            );
                          }
                        }}
                      />
                      {item.children && (
                        <FormWatcher watchPath={item.elementName}>
                          {({ watchedValue }) => {
                            return (
                              <div style={getActivableStyles(!!watchedValue)}>
                                {item.children}
                              </div>
                            );
                          }}
                        </FormWatcher>
                      )}
                    </VerticalLayout>
                  );
                })}
              </FormGroup>
            </FormControl>
          ),
        }}
      />
    </>
  );
};
