import { padding } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const HospitalContactPerson = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading>
        {translations.patientForms.generalForm.sectionTwo.detailsSocialWorker}
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.sectionTwo.socialWorkerName
          }
          large
          elementName="social_worker_full_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.sectionTwo.socialWorkerPhone
          }
          large
          elementName="social_worker_phone_number"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.sectionTwo.socialWorkerFax
          }
          large
          elementName="social_worker_fax_number"
        />
      </SectionRow>
    </>
  );
};
