import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Section,
  SectionRow,
  TextAreaWithLabel,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import {
  DRG_CODE_CHARACTER_LIMIT,
  ICD_CODE_CHARACTER_LIMIT,
} from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

export const SectionOne = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvMedicalForm.sectionOne.title}>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextAreaWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionOne
              .descriptionDiagnosis
          }
          elementName="main_diagnosis"
          marginOverride={margin(0)}
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionOne.diagnosisIcd
          }
          large
          elementName="icd_code_1_code"
          characterLimit={ICD_CODE_CHARACTER_LIMIT}
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionOne.drgGroup}
          elementName="drg_code"
          large
          characterLimit={DRG_CODE_CHARACTER_LIMIT}
        />
      </SectionRow>
    </Section>
  );
};
