import Translations from "translations/types";

const PARALLEL_SEARCH_KEY = "parallel_search" as const;

const PARALLEL_SEARCH_STEPS = {
  NEW_SEARCH_TAB: "new_search_tab",
  OLD_SEARCH_TAB: "old_search_tab",
} as const;

export const PARALLEL_SEARCH = {
  key: PARALLEL_SEARCH_KEY,
  steps: {
    [PARALLEL_SEARCH_STEPS.NEW_SEARCH_TAB]: {
      key: PARALLEL_SEARCH_STEPS.NEW_SEARCH_TAB,
      title: (t: Translations) => t.tours.parallelSearch.stepA.title,
      intro: (t: Translations) => t.tours.parallelSearch.stepA.intro,
      step: 1,
    },
    [PARALLEL_SEARCH_STEPS.OLD_SEARCH_TAB]: {
      key: PARALLEL_SEARCH_STEPS.OLD_SEARCH_TAB,
      title: (t: Translations) => t.tours.parallelSearch.stepB.title,
      intro: (t: Translations) => t.tours.parallelSearch.stepB.intro,
      step: 2,
    },
  },
} as const;
