import { FormControl } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
} from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_14 } from "ds_legacy/materials/typography";
import {
  CheckboxWithTextfield,
  PatientFormSubheading,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { INFECTIONS_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";
import Translations from "translations/types";

type ComplicationOption = {
  checkboxElementName: string;
  descriptionElementName: string;
  label: (translations: Translations) => string;
};

const COMPLICATION_OPTIONS: ComplicationOption[] = [
  {
    checkboxElementName: "complication_cause_maindiagnosis",
    descriptionElementName: "complication_cause_maindiagnosis_description",
    label: (translations: Translations) =>
      translations.patientForms.medicalForm.sectionTwo.relatedToDiagnosis,
  },
  {
    checkboxElementName: "complication_cause_cardiovascular",
    descriptionElementName: "complication_cause_cardiovascular_description",
    label: (translations: Translations) =>
      translations.patientForms.medicalForm.sectionTwo.optionCardiovascular,
  },
  {
    checkboxElementName: "complication_cause_other",
    descriptionElementName: "complication_cause_other_description",
    label: (translations: Translations) =>
      translations.patientForms.medicalForm.sectionTwo.optionOtherComplication,
  },
];

export const Complications = () => {
  const translations = useTranslations();

  const infectionOptions: RadioOptionV2[] = [
    {
      id: PREDICAMENT_STATE_NO,
      value: PREDICAMENT_STATE_NO,
      label: translations.actions.no,
    },
    {
      id: PREDICAMENT_STATE_YES,
      value: PREDICAMENT_STATE_YES,
      label: translations.actions.yes,
      subForm: (
        <TextAreaWithLabel
          characterLimit={INFECTIONS_CHARACTER_LIMIT}
          flatModel
          label={translations.patientForms.medicalForm.sectionTwo.typeOfGerms}
          elementName="infections"
          marginOverride={margin(0, 0, 1)}
        />
      ),
    },
    {
      id: PREDICAMENT_STATE_UNKNOWN,
      value: PREDICAMENT_STATE_UNKNOWN,
      label: translations.patientForms.medicalForm.sectionTwo.germsUnknown,
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionTwo.complications}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="complication_in_treatment_process"
        label={translations.patientForms.medicalForm.sectionTwo.complications}
        formLabelSx={visuallyHidden}
        sideMutation={(value, mutate) => {
          if (value === PREDICAMENT_STATE_NO) {
            COMPLICATION_OPTIONS.forEach((option) => {
              mutate(null, option.checkboxElementName);
              mutate(null, option.descriptionElementName);
            });
          }
        }}
        startWithNo
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout overflow="visible">
              <FormControl component="fieldset" sx={{ gap: sizing(1) }}>
                <Body as="legend" style={visuallyHidden}>
                  {
                    translations.patientForms.medicalForm.sectionTwo
                      .complications
                  }
                </Body>
                {COMPLICATION_OPTIONS.map((option) => (
                  <CheckboxWithTextfield
                    key={option.checkboxElementName}
                    checkboxElementName={option.checkboxElementName}
                    descriptionElementName={option.descriptionElementName}
                    label={option.label(translations)}
                  />
                ))}
              </FormControl>
              <RadioGroupV2
                elementName="infections_state"
                flatModel
                options={infectionOptions}
                label={translations.patientForms.medicalForm.sectionTwo.germs}
                formControlLabelSx={{
                  paddingBottom: sizing(0.5),
                  marginLeft: 0,
                }}
                formLabelSx={{
                  padding: padding(1, 0),
                  fontSize: FONT_SIZE_14,
                }}
                radioSx={RADIO_WHITE_BACKGROUND}
              />
            </VerticalLayout>
          ),
        }}
      />
    </>
  );
};
