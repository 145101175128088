import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  PatientFormSubheading,
  SMALL_INPUT_WIDTH,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const CostCoverage = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.generalForm.sectionTwo.costCoverage}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="prefered_clinic_contacted"
        label={translations.patientForms.generalForm.sectionTwo.costCoverage}
        formLabelSx={visuallyHidden}
        sideMutation={(_value, mutate) => {
          mutate(null, "prefered_clinic_contacted_details");
          mutate(null, "prefered_clinic_contacted_start_date");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout gap={sizing(1)} padding={padding(0, 0, 1)}>
              <TextAreaWithLabel
                elementName="prefered_clinic_contacted_details"
                flatModel
                label={
                  translations.patientForms.generalForm.sectionTwo.rehabDetails
                }
                marginOverride={margin(0)}
              />
              <DatePickerWithLabel
                bold
                elementName="prefered_clinic_contacted_start_date"
                flatModel
                inputSx={{
                  width: SMALL_INPUT_WIDTH,
                }}
                label={
                  translations.patientForms.generalForm.sectionTwo
                    .rehabIntakeDate
                }
                wrapperSx={{ width: "100%" }}
              />
            </VerticalLayout>
          ),
        }}
      />
    </>
  );
};
