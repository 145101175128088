import { visuallyHidden } from "@mui/utils";
import { EARLY_REHAB_CRITERIA } from "core/consts";
import ConnectedCheckboxGroup, {
  CheckboxGroupProps,
} from "ds_legacy/components/CheckboxGroup";
import { sizing } from "ds_legacy/materials/metrics";
import { PatientFormSubheading } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const EarlyRehabCriteria = () => {
  const translations = useTranslations();

  const options: CheckboxGroupProps["items"] = [
    {
      id: EARLY_REHAB_CRITERIA.INTENSIVE_CARE_MONITORING,
      label:
        translations.patientForms.medicalForm.sectionThree
          .intensiveCareMonitoring,
    },
    {
      id: EARLY_REHAB_CRITERIA.INTERMITTENT_VENTILATION,
      label:
        translations.patientForms.medicalForm.sectionThree
          .intermittentVentilation,
    },
    {
      id: EARLY_REHAB_CRITERIA.BEHAVIOURAL_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.behaviouralDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.COMMUNICATION_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree
          .communicationDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.TRACHEOSTOMA,
      label: translations.patientForms.medicalForm.sectionThree.tracheostoma,
    },
    {
      id: EARLY_REHAB_CRITERIA.ORIENTATION_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.orientationDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.SWALLOWING_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.swallowingDisorder,
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionThree.earlyRehabIndex}
      </PatientFormSubheading>
      <ConnectedCheckboxGroup
        elementName="reha_capacity_early_rehab_criteria"
        formGroupStyle={{ gap: sizing(1) }}
        label={
          translations.patientForms.medicalForm.sectionThree.earlyRehabIndex
        }
        labelStyle={visuallyHidden}
        items={options}
      />
    </>
  );
};
