import { validateZipcode } from "core/model/utils/location";
import { validateCharacterLimit } from "core/model/utils/strings";
import {
  GendersExtended,
  HealthInsuranceType,
  LackOfCapacity,
  LegalGuardian,
  MaritalStatus,
  PredicamentState,
  PriorEmploymentStatus,
  RequestedRehaService,
  RequestedRehaServiceType,
  VisuallyImpairedDocument,
  YesNoFieldOption,
} from "core/types";
import { convertModelDefinition, valueDef } from "react-forms-state/src";
import {
  PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
  SEND_DOCUMENTS_CHARACTER_LIMIT,
} from "../../utils";

export type GeneralFormPensionDataOld = {
  _claim_for_damages: YesNoFieldOption | null;
  _contributions_to_foreign_insurance_info: YesNoFieldOption | null;
  _health_damages: YesNoFieldOption | null;
  application_submitted: string | null;
  before_retirement_pension: string | null;
  claim_for_damages: {
    claim_for_damages_description: string | null;
    claim_for_damages_file_number: string | null;
    claim_for_damages_origin: string | null;
  } | null;
  consent_of_the_insured: YesNoFieldOption | null;
  contact_guardian: string | null;
  contributions_to_foreign_insurance_current: YesNoFieldOption | null;
  contributions_to_foreign_insurance_info: {
    contributions_to_foreign_insurance_info_from_to: string | null;
    contributions_to_foreign_insurance_info_state: string | null;
  } | null;
  contributions_to_statutory_pension: YesNoFieldOption | null;
  day_reha: boolean | null;
  day_reha_with_oncology: boolean | null;
  doctor_in_charge_in_hospital: string | null;
  doctor_in_charge_in_hospital_first_name: string | null;
  doctor_in_charge_in_hospital_phone: string | null;
  document_braille_longhand: boolean | null;
  document_braille_shorthand: boolean | null;
  document_cs: boolean | null;
  document_daisy: boolean | null;
  document_large_print: boolean | null;
  employment_status: number | null;
  full_reha: boolean | null;
  full_reha_with_oncology: boolean | null;
  gender_extended: number | null;
  health_damages: {
    health_damage_file_number: string | null;
    health_damage_origin: string | null;
    health_damages_description: string | null;
  };
  hospital_name_address: string | null;
  insurance_company_name_address: string | null;
  insurance_type: number | null;
  is_legal_guardian_authorized: boolean | null;
  is_legal_guardian_custodian: boolean | null;
  is_legal_guardian_statutory_representative: boolean | null;
  is_legal_guardian_supervisor: boolean | null;
  last_employment: string | null;
  legal_guardian_email: string | null;
  legal_guardian_house_number: string | null;
  legal_guardian_phone: string | null;
  legal_guardian_town: string | null;
  legal_guardian_zip_code: string | null;
  marital_status: number | null;
  needs_disability_equipment: string | null;
  patient_bank_account_holder: string | null;
  patient_bank_iban: string | null;
  patient_bank_name: string | null;
  patient_birth_name: string | null;
  patient_birthdate: string | null;
  patient_city: string | null;
  patient_first_name: string | null;
  patient_last_name: string | null;
  patient_maiden_name: string | null;
  patient_nationality: string | null;
  patient_phone_number: string | null;
  patient_street_housenumber: string | null;
  patient_unable_to_work_before_hospitalization: string | null;
  patient_zipcode: string | null;
  pension_insurance_company_name: string | null;
  postal_code_doctor: string | null;
  preferred_rehab_clinic: string | null;
  prior_employment_status: number[] | null;
  reha_with_oncology: boolean | null;
  reha_without_oncology: boolean | null;
  retirement_pension_received: string | null;
  risk_of_incapacity_caused_by_accident_third_person: YesNoFieldOption | null;
  send_documents_per_email: boolean | null;
  social_worker_name_number: string | null;
  special_payments_from_civil_services: YesNoFieldOption | null;
  statutory_pension_insurance_number: string | null;
  street_house_number_doctor: string | null;
  structured_treatment_plan: string | null;
  third_party_request: YesNoFieldOption | null;
  town_doctor: string | null;
  unemployment_benefit_type_2: YesNoFieldOption | null;
} | null;

export type GeneralFormPensionData = {
  application_submitted?: string | null;
  application_submitted_applicable?: PredicamentState | null;
  before_retirement_pension?: string | null;
  before_retirement_pension_applicable?: PredicamentState | null;
  care_person_necessary?: PredicamentState | null;
  claim_for_damages_approved?: PredicamentState | null;
  claim_for_damages_description?: number | null;
  claim_for_damages_file_number?: string | null;
  claim_for_damages_origin?: string | null;
  consent_of_the_insured?: LackOfCapacity | null;
  contact_guardian?: string | null;
  contributions_to_foreign_insurance_current?: PredicamentState | null;
  contributions_to_foreign_insurance_info?: PredicamentState | null;
  contributions_to_foreign_insurance_info_from_to?: string | null;
  contributions_to_foreign_insurance_info_state?: string | null;
  contributions_to_statutory_pension?: PredicamentState | null;
  doctor_in_charge_in_hospital?: string | null;
  doctor_in_charge_in_hospital_first_name?: string | null;
  doctor_in_charge_in_hospital_phone?: string | null;
  employment_status?: PriorEmploymentStatus | null;
  gender_extended?: GendersExtended | null;
  health_damage_file_number?: string | null;
  health_damage_origin?: string | null;
  health_damage_present?: PredicamentState | null;
  health_damages_description?: string | null;
  hospital_name_address?: string | null;
  insurance_company_name_address?: string | null;
  insurance_name?: string | null;
  insurance_type?: HealthInsuranceType | null;
  jobcenter_name?: string | null;
  last_employment?: string | null;
  legal_guardian_email?: string | null;
  legal_guardian_house_number?: string | null;
  legal_guardian_phone?: string | null;
  legal_guardian_town?: string | null;
  legal_guardian_type?: LegalGuardian | null;
  legal_guardian_zip_code?: string | null;
  marital_status?: MaritalStatus | null;
  needs_disability_equipment?: string | null;
  needs_disability_equipment_applicable?: PredicamentState | null;
  patient_bank_account_holder?: string | null;
  patient_bank_iban?: string | null;
  patient_bank_name?: string | null;
  patient_birth_name?: string | null;
  patient_birthdate?: number | null;
  patient_city?: string | null;
  patient_first_name?: string | null;
  patient_last_name?: string | null;
  patient_maiden_name?: string | null;
  patient_nationality?: string | null;
  patient_phone_number?: string | null;
  patient_street_housenumber?: string | null;
  patient_unable_to_work_before_hospitalization?: PredicamentState | null;
  patient_unable_to_work_before_hospitalization_date?: number | null;
  patient_zipcode?: string | null;
  pension_insurance_company_name?: string | null;
  postal_code_doctor?: string | null;
  preferred_rehab_clinic?: string | null;
  prior_employment_status?: PriorEmploymentStatus | null;
  requested_rehab_service?: RequestedRehaService | null;
  requested_rehab_service_type_insured?: RequestedRehaServiceType | null;
  requested_rehab_service_type_relatives?: RequestedRehaServiceType | null;
  retirement_pension_received?: string | null;
  retirement_pension_received_applicable?: PredicamentState | null;
  risk_of_incapacity_caused_by_accident_third_person?: PredicamentState | null;
  send_documents_per_email?: boolean | null;
  send_documents_per_email_description?: string | null;
  social_worker_name_number?: string | null;
  special_payments_from_civil_services?: PredicamentState | null;
  statutory_pension_insurance_number?: string | null;
  street_house_number_doctor?: string | null;
  structured_treatment_plan?: string | null;
  third_party_request?: PredicamentState | null;
  town_doctor?: string | null;
  unemployment_benefit_type_2?: PredicamentState | null;
  visually_impaired_document?: VisuallyImpairedDocument[] | null;
};

export const generalFormPensionDefinition = convertModelDefinition({
  // section zero
  ...valueDef("statutory_pension_insurance_number", {
    validate: (value, props) =>
      validateCharacterLimit(PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT)(
        value,
        props,
      ),
  }),
  ...valueDef("pension_insurance_company_name"),
  ...valueDef("insurance_company_name_address"),
  ...valueDef("hospital_name_address"),
  ...valueDef("social_worker_name_number"),
  ...valueDef("preferred_rehab_clinic"),

  // section one
  ...valueDef("requested_rehab_service"),
  ...valueDef("requested_rehab_service_type_insured"),
  ...valueDef("requested_rehab_service_type_relatives"),

  // section two
  ...valueDef("patient_first_name"),
  ...valueDef("patient_last_name"),
  ...valueDef("patient_birth_name"),
  ...valueDef("patient_maiden_name"),
  ...valueDef("gender_extended"),
  ...valueDef("patient_birthdate"),
  ...valueDef("patient_street_housenumber"),
  ...valueDef("patient_zipcode", { validate: validateZipcode(false) }),
  ...valueDef("patient_city"),
  ...valueDef("patient_phone_number"),
  ...valueDef("patient_nationality"),

  // section three
  ...valueDef("care_person_necessary"),

  // section four
  ...valueDef("marital_status"),
  ...valueDef("last_employment"),

  // section five
  ...valueDef("employment_status"),

  // section six
  ...valueDef("prior_employment_status"),

  // section seven
  ...valueDef("insurance_type"),
  ...valueDef("insurance_name"),
  ...valueDef("patient_unable_to_work_before_hospitalization"),
  ...valueDef("patient_unable_to_work_before_hospitalization_date"),

  // section eight
  ...valueDef("doctor_in_charge_in_hospital"),
  ...valueDef("doctor_in_charge_in_hospital_first_name"),
  ...valueDef("street_house_number_doctor"),
  ...valueDef("postal_code_doctor", { validate: validateZipcode(false) }),
  ...valueDef("town_doctor"),
  ...valueDef("doctor_in_charge_in_hospital_phone"),

  // section nine
  ...valueDef("contributions_to_statutory_pension"),
  ...valueDef("contributions_to_foreign_insurance_info"),
  ...valueDef("contributions_to_foreign_insurance_info_from_to"),
  ...valueDef("contributions_to_foreign_insurance_info_state"),
  ...valueDef("contributions_to_foreign_insurance_current"),

  // section ten
  ...valueDef("unemployment_benefit_type_2"),
  ...valueDef("jobcenter_name"),

  // section eleven
  ...valueDef("special_payments_from_civil_services"),
  ...valueDef("retirement_pension_received_applicable"),
  ...valueDef("retirement_pension_received"),
  ...valueDef("before_retirement_pension_applicable"),
  ...valueDef("before_retirement_pension"),
  ...valueDef("health_damage_present"),
  ...valueDef("health_damage_origin"),
  ...valueDef("health_damage_file_number"),
  ...valueDef("health_damages_description"),
  ...valueDef("application_submitted_applicable"),
  ...valueDef("application_submitted"),
  ...valueDef("risk_of_incapacity_caused_by_accident_third_person"),
  ...valueDef("claim_for_damages_approved"),
  ...valueDef("claim_for_damages_origin"),
  ...valueDef("claim_for_damages_file_number"),
  ...valueDef("claim_for_damages_description"),

  // section twelve
  ...valueDef("third_party_request"),
  ...valueDef("contact_guardian"),
  ...valueDef("legal_guardian_type"),
  ...valueDef("legal_guardian_house_number"),
  ...valueDef("legal_guardian_zip_code", { validate: validateZipcode(false) }),
  ...valueDef("legal_guardian_town"),
  ...valueDef("legal_guardian_phone"),
  ...valueDef("legal_guardian_email"),
  ...valueDef("consent_of_the_insured"),

  // section thirteen
  ...valueDef("needs_disability_equipment_applicable"),
  ...valueDef("needs_disability_equipment"),

  // section fourteen
  ...valueDef("send_documents_per_email"),
  ...valueDef("send_documents_per_email_description", {
    validate: (value, props) =>
      validateCharacterLimit(SEND_DOCUMENTS_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("visually_impaired_document"),
});
