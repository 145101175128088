import { Section } from "dsl/ecosystems/PatientForms/components";
import { RehabGoals } from "./RehabGoals";

export const SectionFour = () => {
  return (
    <Section>
      <RehabGoals />
    </Section>
  );
};
