import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import classNames from "classnames";
import { isNewTheme } from "core/model/utils/featureFlags";
import { ArrowDown, ArrowUp } from "ds/icons";
import { iconHoverBorderStyle } from "ds_legacy/materials/layouts";
import { dp } from "ds_legacy/materials/metrics";
import { DropdownIndicatorProps } from "react-select";
import { SelectOption } from "./types";

function getIconStyle({
  isDisabled,
  theme,
}: {
  isDisabled?: boolean;
  theme: Theme;
}) {
  return {
    width: isNewTheme ? dp(16) : dp(24),
    height: isNewTheme ? dp(16) : dp(24),
    opacity: isDisabled ? 0.25 : 0.8,
    borderRadius: dp(24),
    ":hover": iconHoverBorderStyle(theme),
  };
}

export function DropdownIndicator({
  isDisabled,
  selectProps: { classes, light, menuIsOpen },
}: DropdownIndicatorProps<SelectOption>) {
  const theme = useTheme();
  const iconStyle = getIconStyle({ isDisabled, theme });
  return (
    <div className={classNames(classes.dropdownIndicatorContainer)}>
      {menuIsOpen ? (
        <ArrowUp
          style={{ color: light ? "white" : "currentColor", ...iconStyle }}
        />
      ) : (
        <ArrowDown
          style={{
            color: light ? "white" : "currentColor",
            ...iconStyle,
            opacity: 0.5,
          }}
        />
      )}
    </div>
  );
}
