import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { SectionOne } from "./SectionOne";
import { SectionOneHeader } from "./SectionOne/header";
import { SectionTwo } from "./SectionTwo";
import { SectionTwoHeader } from "./SectionTwo/header";
import { SectionZero } from "./SectionZero";

export function GeneralFormUniversalPresenter() {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <SectionZero />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionOneHeader />
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwoHeader />
        <SectionTwo />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
