import { Section } from "dsl/ecosystems/PatientForms/components";
import { DoctorDetails } from "./DoctorDetails";
import { SpecialRehabRequirements } from "./SpecialRehabRequirements";
import { Transportation } from "./Transportation";

export const SectionSix = () => {
  return (
    <Section>
      <SpecialRehabRequirements />
      <Transportation />
      <DoctorDetails />
    </Section>
  );
};
