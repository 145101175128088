import { WHITE } from "ds_legacy/materials/colors";
import { margin } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const LivingSituation = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.generalForm.sectionI.questionLivingSituation}
      </PatientFormSubheading>

      <TextInputWithLabel
        elementName="living_lift_description"
        label={
          translations.patientForms.generalForm.sectionI.livingSituationFloor
        }
        marginOverride={margin(0)}
        labelWrapperSx={{ width: "100%" }}
        style={{
          width: SMALL_INPUT_WIDTH,
          minWidth: SMALL_INPUT_MIN_WIDTH,
          background: WHITE,
        }}
      />
      <YesNoRadioWithLabel
        elementName="living_lift"
        label={
          translations.patientForms.generalForm.sectionI.livingSituationElevator
        }
      />
      <TextAreaWithLabel
        elementName="living_special"
        label={
          translations.patientForms.generalForm.sectionI
            .livingSituationSpecifications
        }
        marginOverride={margin(0)}
      />
    </>
  );
};
