import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ONTOLOGY_PRIOR_EMPLOYMENT_STATUS } from "core/model/utils/ontologies";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { sizing } from "ds_legacy/materials/metrics";
import { Section } from "dsl/ecosystems/PatientForms/components";
import { OntologyRadioGroupV2 } from "dsl/molecules/OntologyRadioGroup";
import { useTranslations } from "translations";

export const SectionSix = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionFive.title}>
      <OntologyRadioGroupV2
        elementName="prior_employment_status"
        label={translations.patientForms.drvGeneralForm.sectionFive.title}
        type={ONTOLOGY_PRIOR_EMPLOYMENT_STATUS}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
    </Section>
  );
};
