import { padding } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const DoctorDetails = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading>
        {
          translations.patientForms.medicalForm.sectionSix
            .contactInformationDoctor
        }
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.medicalForm.sectionSix.nameDoctor}
          large
          elementName="doctor_in_charge_in_hospital"
        />
      </SectionRow>
      <SectionRow customPadding={padding(2, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.medicalForm.sectionSix.phoneDoctor}
          large
          elementName="doctor_in_charge_in_hospital_phone"
        />
      </SectionRow>
    </>
  );
};
