import { REQUEST_FORM_FIELDS, VALIDATION_INVALID } from "core/consts";
import { validateZipcode } from "core/model/utils/location";
import {
  TransitionalCareImportedAuctionRequest,
  TransitionalCareRequest,
} from "core/types";
import { getUnixTime } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { FieldValidation } from "react-forms-state";
import Translations from "translations/types";
import { TransitionalRequestValidationType } from ".";

export const getDefaultAddingState = (id: number): TransitionalCareRequest => ({
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ADDRESS]: "",
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_CITY]: "",
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE]: "",
  [REQUEST_FORM_FIELDS.CAREPROVIDER_NAME]: "",
  [REQUEST_FORM_FIELDS.REQUEST_SENT_DATE]: null,
  [REQUEST_FORM_FIELDS.REQUEST_SENT_TIME]: null,
  [REQUEST_FORM_FIELDS.REQUEST_STATUS]: null,
  request_id: null,
  edited: false,
  manual: true,
  id,
});

export const validateFields = (
  request: TransitionalCareRequest,
  translations: Translations,
): [boolean, TransitionalRequestValidationType] => {
  const validationMessage = {
    customMessage:
      translations.patientForms.transitionalCareForm.validationErrorMessage,
  };
  const errorMap = Object.values(REQUEST_FORM_FIELDS).reduce((acc, field) => {
    let validation: FieldValidation = false;

    switch (field) {
      case REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE: {
        const zipcodeValidation = validateZipcode(true)(request[field]);

        switch (zipcodeValidation) {
          case true:
            validation = true;
            break;
          case VALIDATION_INVALID:
            validation = { infos: VALIDATION_INVALID };
            break;
          default:
            validation = { infos: validationMessage };
        }
        break;
      }
      case REQUEST_FORM_FIELDS.REQUEST_SENT_DATE:
        validation = !!request[field] || {
          infos: validationMessage,
        };
        break;
      case REQUEST_FORM_FIELDS.REQUEST_STATUS:
        validation = request[field] != null || {
          infos: validationMessage.customMessage,
        };
        break;
      default: {
        validation = !!request[field] || { infos: validationMessage };
        break;
      }
    }
    return { ...acc, [field]: validation };
  }, {}) as TransitionalRequestValidationType;

  const hasError = Object.values(errorMap).some((error) => error !== true);

  return [hasError, errorMap];
};

export const getLastId = (list: TransitionalCareRequest[]) =>
  list
    .clone()
    .sort((a, b) => a.id - b.id)
    .at(-1)?.id ?? 0;

export const toFormRequest =
  (startId: number) =>
  (
    request: TransitionalCareImportedAuctionRequest,
    index: number,
  ): TransitionalCareRequest => ({
    id: startId + index + 1,
    request_id: request.id,
    manual: false,
    edited: false,
    [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ADDRESS]:
      request.careprovider?.address?.address ?? "",
    [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_CITY]:
      request.careprovider?.address?.city ?? "",
    [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE]:
      request.careprovider?.address?.zip_code ?? "",
    [REQUEST_FORM_FIELDS.CAREPROVIDER_NAME]: request.careprovider?.name ?? "",
    [REQUEST_FORM_FIELDS.REQUEST_SENT_DATE]:
      request.created_at ?? getUnixTime(zonedTimeToUtc(new Date(), "UTC")),
    [REQUEST_FORM_FIELDS.REQUEST_SENT_TIME]:
      request.created_at ?? getUnixTime(zonedTimeToUtc(new Date(), "UTC")),
    [REQUEST_FORM_FIELDS.REQUEST_STATUS]: request.request_status ?? null,
  });
