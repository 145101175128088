import Chip from "@mui/material/Chip";
import { emphasize } from "@mui/material/styles";
import { styled } from "@mui/system";
import { CancelIcon } from "ds/icons";
import { dp } from "ds_legacy/materials/metrics";
import { FONT_SIZE_24 } from "ds_legacy/materials/typography";
import { MultiValueProps } from "react-select";
import { SelectOption, SelectType } from "./types";

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) =>
    !["isFocused", "withChipEllipsis"].includes(prop as string),
})<Pick<SelectType, "withChipEllipsis"> & { isFocused: boolean | undefined }>(
  ({ isFocused, theme, withChipEllipsis }) => ({
    margin: theme.spacing(1 / 2, 1 / 4),
    maxWidth: withChipEllipsis ? dp(withChipEllipsis) : undefined,
    whiteSpace: withChipEllipsis ? "nowrap" : undefined,
    overflow: withChipEllipsis ? "hidden" : undefined,
    textOverflow: withChipEllipsis ? "ellipsis" : undefined,
    background: isFocused
      ? theme.palette.mode === "light"
        ? emphasize(theme.palette.grey[300], 0.08)
        : emphasize(theme.palette.grey[600], 0.08)
      : undefined,
  }),
);

export function MultiValue({
  children,
  data,
  isFocused,
  removeProps,
  selectProps: { useShortValue },
}: MultiValueProps<SelectOption>) {
  return (
    <StyledChip
      isFocused={isFocused}
      tabIndex={-1}
      label={useShortValue ? data.value : data.label || children}
      onDelete={removeProps.onClick}
      deleteIcon={
        <CancelIcon
          style={{ width: dp(24), height: dp(24) }}
          fontSize={FONT_SIZE_24}
          {...(removeProps as any)}
        />
      }
    />
  );
}
