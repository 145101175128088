import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  LARGE_INPUT_WIDTH,
  PatientFormSubheading,
  Section,
  SMALL_INPUT_MIN_WIDTH,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionEleven = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionTen.title}>
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionTen.pensionInformation}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="special_payments_from_civil_services"
        label={
          translations.patientForms.drvGeneralForm.sectionTen.pensionInformation
        }
        formLabelSx={visuallyHidden}
        startWithNo
      />
      <PatientFormSubheading>
        {translations.patientForms.drvGeneralForm.sectionTen.pensionBenefits}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="retirement_pension_received_applicable"
        label={
          translations.patientForms.drvGeneralForm.sectionTen.penisonApplication
        }
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "retirement_pension_received");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextInputWithLabel
              elementName="retirement_pension_received"
              flatModel
              label={
                translations.patientForms.drvGeneralForm.sectionTen
                  .namePensionInsurance
              }
              marginOverride={margin(0)}
              labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
              inputProps={{
                style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
              }}
            />
          ),
        }}
      />
      <PatientFormSubheading>
        {
          translations.patientForms.drvGeneralForm.sectionTen
            .pensionOldAgeBenefits
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="before_retirement_pension_applicable"
        label={
          translations.patientForms.drvGeneralForm.sectionTen.otherBenefits
        }
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "before_retirement_pension");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextInputWithLabel
              elementName="before_retirement_pension"
              flatModel
              label={
                translations.patientForms.drvGeneralForm.sectionTen.benefitsType
              }
              marginOverride={margin(0)}
              labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
              inputProps={{
                style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
              }}
            />
          ),
        }}
      />
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.drvGeneralForm.sectionTen
            .workRelatedAccident
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="health_damage_present"
        label={
          translations.patientForms.drvGeneralForm.sectionTen
            .workRelatedAccident
        }
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "health_damage_origin");
          mutate(null, "health_damage_file_number");
          mutate(null, "health_damages_description");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout gap={sizing(3)}>
              <HorizontalLayout gap={sizing(3)}>
                <TextInputWithLabel
                  elementName="health_damage_origin"
                  flatModel
                  label={
                    translations.patientForms.drvGeneralForm.sectionTen
                      .recognisedBy
                  }
                  marginOverride={margin(0)}
                  labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  inputProps={{
                    style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
                  }}
                />
                <TextInputWithLabel
                  elementName="health_damage_file_number"
                  flatModel
                  label={
                    translations.patientForms.drvGeneralForm.sectionTen
                      .fileNumber
                  }
                  marginOverride={margin(0)}
                  labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  inputProps={{
                    style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
                  }}
                />
              </HorizontalLayout>
              <TextInputWithLabel
                elementName="health_damages_description"
                flatModel
                label={
                  translations.patientForms.drvGeneralForm.sectionTen
                    .healthIssues
                }
                marginOverride={margin(0)}
                labelWrapperSx={{ width: "100%" }}
              />
            </VerticalLayout>
          ),
        }}
      />
      <YesNoRadioWithLabel
        elementName="application_submitted_applicable"
        label={
          translations.patientForms.drvGeneralForm.sectionTen
            .submittedApplication
        }
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "application_submitted");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextInputWithLabel
              elementName="application_submitted"
              flatModel
              label={
                translations.patientForms.drvGeneralForm.sectionTen
                  .submittedWhere
              }
              marginOverride={margin(0)}
              labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
              inputProps={{
                style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
              }}
            />
          ),
        }}
      />
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.drvGeneralForm.sectionTen.accidentOrPerson}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="risk_of_incapacity_caused_by_accident_third_person"
        label={
          translations.patientForms.drvGeneralForm.sectionTen.accidentOrPerson
        }
        formLabelSx={visuallyHidden}
        startWithNo
      />
      <YesNoRadioWithLabel
        elementName="claim_for_damages_approved"
        label={translations.patientForms.drvGeneralForm.sectionTen.damageClaims}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "claim_for_damages_origin");
          mutate(null, "claim_for_damages_file_number");
          mutate(null, "claim_for_damages_description");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout gap={sizing(3)}>
              <HorizontalLayout gap={sizing(3)}>
                <TextInputWithLabel
                  elementName="claim_for_damages_origin"
                  flatModel
                  label={
                    translations.patientForms.drvGeneralForm.sectionTen
                      .compensationClaims
                  }
                  marginOverride={margin(0)}
                  labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  inputProps={{
                    style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
                  }}
                />
                <TextInputWithLabel
                  elementName="claim_for_damages_file_number"
                  flatModel
                  label={
                    translations.patientForms.drvGeneralForm.sectionTen
                      .fileNumber
                  }
                  marginOverride={margin(0)}
                  labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  inputProps={{
                    style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
                  }}
                />
              </HorizontalLayout>
              <DatePickerWithLabel
                flatModel
                label={
                  translations.patientForms.drvGeneralForm.sectionTen
                    .compensatedOn
                }
                elementName="claim_for_damages_description"
                inputSx={{
                  width: "100%",
                  minWidth: SMALL_INPUT_MIN_WIDTH,
                }}
                wrapperSx={{ width: LARGE_INPUT_WIDTH }}
              />
            </VerticalLayout>
          ),
        }}
      />
    </Section>
  );
};
