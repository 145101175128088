import { WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { sizing } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import { PatientFormSubheading } from "dsl/ecosystems/PatientForms/components";
import { SingerAssessment } from "dsl/organisms/IndependenceScale";
import { useTranslations } from "translations";

export const SingerProfile = ({
  onChange,
}: {
  onChange: (value: any, statePath: string) => void;
}) => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionTwo.longTermImpairments}
      </PatientFormSubheading>
      <VerticalLayout style={{ marginLeft: sizing(-1.5) }}>
        <Body>{translations.patientForms.medicalForm.sectionTwo.singer}</Body>
        <SingerAssessment
          onChange={onChange}
          datePickerProps={{
            textInputVariant: "outlined",
            inputSx: {
              background: WHITE,
            },
          }}
          textInputProps={{
            variant: "outlined",
            style: {
              background: WHITE,
            },
          }}
        />
      </VerticalLayout>
    </>
  );
};
