import FormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText";
import { VALIDATION_INVALID } from "core/consts";
import { isValid } from "react-forms-state";
import { useTranslations } from "translations";
import Translations from "translations/types";

function getError(
  validation: { infos: string } | undefined,
  validateOverride: string | undefined,
  translations: Translations,
) {
  if (!validation) return null;

  if (validateOverride) return validateOverride;
  switch (validation.infos) {
    case VALIDATION_INVALID:
      return translations.actions.invalid;
    default:
      return translations.general.required;
  }
}

export function getHelperText({
  hasCustomValidation,
  translations,
  validateOverride,
  validation,
}: {
  hasCustomValidation: boolean;
  translations: Translations;
  validateOverride?: string;
  validation?: any;
}) {
  if (isValid(validation) || validateOverride === " ") return null;
  let validationText = getError(validation, validateOverride, translations);

  if (
    (hasCustomValidation && typeof validation?.infos === "string") ||
    validation?.infos?.customMessage
  ) {
    validationText =
      typeof validation?.infos === "string"
        ? validation?.infos
        : validation.infos.customMessage;
  }

  return validationText;
}

export const Validation = ({
  elementName,
  formHelperTextSx,
  hasCustomValidation,
  id,
  validateOverride,
  validation,
}: {
  elementName: string;
  formHelperTextSx?: FormHelperTextProps["sx"];
  hasCustomValidation: boolean;
  id: string;
  validateOverride?: string;
  validation?: any;
}) => {
  const translations = useTranslations();
  if (isValid(validation) || validateOverride === " ") return null;

  return (
    <FormHelperText
      id={id}
      data-testid={`${elementName}-form-helper-text`}
      error
      sx={{ ...formHelperTextSx }}
    >
      {getHelperText({
        hasCustomValidation,
        translations,
        validateOverride,
        validation,
      })}
    </FormHelperText>
  );
};
