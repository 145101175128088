import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import { margin, padding } from "ds_legacy/materials/metrics";
import {
  LARGE_INPUT_WIDTH,
  PatientFormSubheading,
  Section,
  SMALL_INPUT_MIN_WIDTH,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionThirteen = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionTwelev.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionTwelev.aids}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="needs_disability_equipment_applicable"
        label={translations.patientForms.drvGeneralForm.sectionTwelev.aids}
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "needs_disability_equipment");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextInputWithLabel
              elementName="needs_disability_equipment"
              flatModel
              label={
                translations.patientForms.drvGeneralForm.sectionTwelev
                  .descriptionAids
              }
              marginOverride={margin(0)}
              labelWrapperSx={{ width: LARGE_INPUT_WIDTH }}
              inputProps={{
                style: { width: "100%", minWidth: SMALL_INPUT_MIN_WIDTH },
              }}
            />
          ),
        }}
      />
    </Section>
  );
};
