import { PatientFormsSectionHeader } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionTwoHeader = () => {
  const translations = useTranslations();
  return (
    <PatientFormsSectionHeader>
      {translations.patientForms.generalForm.sectionTwo.title}
    </PatientFormsSectionHeader>
  );
};
