import { REHAB_FORM_NAMES, VISUALLY_IMPAIRED_DOCUMENT } from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import { ValueOf } from "core/types";
import { assertUnreachable } from "dsl/ecosystems/PatientForms/utils";
import { PDFForm } from "pdf-lib";
import { handlePdfDateField, RehabFormDataMapped } from "../../utils";
import { GeneralFormPensionData } from "./modelDefinition";

export const generalPensionFormMapping = ({
  form,
  formData,
  locale,
}: {
  form: PDFForm;
  formData: GeneralFormPensionData;
  locale: Locale;
}) => {
  const handleCheckboxes = <T extends typeof VISUALLY_IMPAIRED_DOCUMENT>({
    formFieldName,
    possibleValues,
    value,
  }: {
    formFieldName: keyof GeneralFormPensionData;
    possibleValues: T;
    value: ValueOf<GeneralFormPensionData>;
  }) => {
    if (!value || !Array.isArray(value) || !value.length) {
      return;
    }
    Object.values(possibleValues).forEach((possibleValue) => {
      const checkbox = form.getCheckBox(`${formFieldName}_${possibleValue}`);
      if ((value as ValueOf<T>[]).includes(possibleValue as ValueOf<T>)) {
        checkbox.check();
      } else {
        checkbox.uncheck();
      }
    });
  };

  Object.entries(formData).forEach(([key, value]) => {
    try {
      const formFieldName = key as keyof RehabFormDataMapped<
        typeof REHAB_FORM_NAMES.GENERAL_PENSION
      >;
      switch (formFieldName) {
        // DATE FIELDS
        case "claim_for_damages_description":
        case "patient_unable_to_work_before_hospitalization_date": {
          handlePdfDateField({
            formFieldName,
            value,
            form,
            locale,
          });
          break;
        }
        case "patient_birthdate": {
          handlePdfDateField({
            formFieldName,
            value,
            form,
            locale,
            removeSeparators: true,
          });
          break;
        }
        // TEXT FIELDS
        case "jobcenter_name":
        case "application_submitted":
        case "before_retirement_pension":
        case "claim_for_damages_file_number":
        case "claim_for_damages_origin":
        case "contact_guardian":
        case "contributions_to_foreign_insurance_info_from_to":
        case "contributions_to_foreign_insurance_info_state":
        case "doctor_in_charge_in_hospital":
        case "doctor_in_charge_in_hospital_first_name":
        case "doctor_in_charge_in_hospital_phone":
        case "health_damage_file_number":
        case "health_damage_origin":
        case "health_damages_description":
        case "hospital_name_address":
        case "insurance_company_name_address":
        case "insurance_name":
        case "last_employment":
        case "legal_guardian_email":
        case "legal_guardian_house_number":
        case "legal_guardian_phone":
        case "legal_guardian_town":
        case "legal_guardian_zip_code":
        case "needs_disability_equipment":
        case "patient_bank_account_holder":
        case "patient_bank_iban":
        case "patient_bank_name":
        case "patient_birth_name":
        case "patient_city":
        case "patient_first_name":
        case "patient_last_name":
        case "patient_maiden_name":
        case "patient_nationality":
        case "patient_phone_number":
        case "patient_street_housenumber":
        case "patient_zipcode":
        case "pension_insurance_company_name":
        case "postal_code_doctor":
        case "preferred_rehab_clinic":
        case "retirement_pension_received":
        case "send_documents_per_email_description":
        case "social_worker_name_number":
        case "statutory_pension_insurance_number":
        case "street_house_number_doctor":
        case "structured_treatment_plan":
        case "town_doctor": {
          if (value != null && typeof value !== "string") {
            console.warn("incorrect value type for text field", {
              formFieldName,
              value,
            });
            break;
          }

          form.getTextField(formFieldName).setText(value || "");
          break;
        }

        // CHECKBOX FIELDS
        case "send_documents_per_email": {
          if (value != null && typeof value !== "boolean") {
            console.warn("incorrect value type for checkbox field", {
              formFieldName,
              value,
            });
            break;
          }

          const checkbox = form.getCheckBox(formFieldName);
          if (value) {
            checkbox.check();
          } else {
            checkbox.uncheck();
          }
          break;
        }

        // RADIO FIELDS
        case "care_person_necessary":
        case "application_submitted_applicable":
        case "before_retirement_pension_applicable":
        case "claim_for_damages_approved":
        case "contributions_to_foreign_insurance_current":
        case "contributions_to_foreign_insurance_info":
        case "contributions_to_statutory_pension":
        case "health_damage_present":
        case "needs_disability_equipment_applicable":
        case "patient_unable_to_work_before_hospitalization":
        case "retirement_pension_received_applicable":
        case "risk_of_incapacity_caused_by_accident_third_person":
        case "special_payments_from_civil_services":
        case "third_party_request":
        case "unemployment_benefit_type_2":
        case "consent_of_the_insured":
        case "employment_status":
        case "gender_extended":
        case "insurance_type":
        case "legal_guardian_type":
        case "marital_status":
        case "prior_employment_status":
        case "requested_rehab_service":
        case "requested_rehab_service_type_insured":
        case "requested_rehab_service_type_relatives": {
          const radio = form.getRadioGroup(formFieldName);
          if (value == null) {
            radio.clear();
            return;
          }
          if (typeof value !== "number") {
            console.warn("incorrect value type for radio field", {
              formFieldName,
              value,
            });
            break;
          }

          radio.select(value.toString());
          break;
        }

        // CHECKBOX GROUP FIELDS
        case "visually_impaired_document": {
          handleCheckboxes({
            possibleValues: VISUALLY_IMPAIRED_DOCUMENT,
            value,
            formFieldName,
          });
          break;
        }

        default: {
          assertUnreachable(formFieldName);
        }
      }
    } catch (err) {
      console.error(
        `error setting field ${key} in PDF: ${getErrorMessage(err)}`,
      );
    }
  });
};
