import { margin, padding } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const PensionApplication = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading>
        {translations.patientForms.generalForm.sectionI.titlePensionApplication}
      </PatientFormSubheading>

      <YesNoRadioWithLabel
        elementName="retirement_pension_applied"
        label={
          translations.patientForms.generalForm.sectionI
            .subtitleRetirementPension
        }
      />
      <TextAreaWithLabel
        elementName="retirement_pension_company"
        label={
          translations.patientForms.generalForm.sectionI
            .placeholderLabelInsuranceName
        }
        marginOverride={margin(0)}
      />
      <YesNoRadioWithLabel
        formLabelSx={{ padding: padding(2, 0, 1) }}
        elementName="retirement_pension_earning_capacity_applied"
        label={
          translations.patientForms.generalForm.sectionI
            .subtitlePensionReducedEarning
        }
      />
      <TextAreaWithLabel
        elementName="retirement_earning_capacity_company"
        label={
          translations.patientForms.generalForm.sectionI
            .placeholderLabelInsuranceName
        }
        marginOverride={margin(0)}
      />
    </>
  );
};
