import { SxProps } from "@mui/material";
import { REQUEST_FORM_FIELDS } from "core/consts";
import { ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS } from "core/model/utils/ontologies";
import { TransitionalCareRequest } from "core/types";
import { fromUnixTime, getUnixTime } from "date-fns";
import { DeleteOutlinedIcon } from "ds/icons";
import {
  DatePickerInputField,
  DateSelectorProps,
} from "ds_legacy/components/DatePickerInputField";
import RSButton from "ds_legacy/components/RSButton";
import {
  SelectInput,
  SelectInputProps,
} from "ds_legacy/components/SelectInput";
import { useOntologyOptions } from "ds_legacy/components/SelectInput/Ontology";
import {
  TextInputField,
  TextInputFieldProps,
} from "ds_legacy/components/TextInputField";
import {
  TimePickerPresenter,
  TimePresenterProps,
} from "ds_legacy/components/TimePicker/input";
import { WHITE } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import { Body, FONT_WEIGHT_SEMI_BOLD } from "ds_legacy/materials/typography";
import {
  LARGE_INPUT_MIN_WIDTH,
  SMALL_INPUT_MIN_WIDTH,
  SectionRow,
  WithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useRef } from "react";
import { isValid } from "react-forms-state";
import { handleInputFocusOnSubmit } from "react-forms-state/src/Form";
import { useTranslations } from "translations";
import { getSectionIdLabel } from ".";
import { FormField, useRequestListContext } from "../RequestListContext";
import { ACTION_TYPES } from "../RequestListContext/reducer";
import { CardIconButton } from "./RequestCard";

const LARGE_INPUT_WIDTH = "40%";
const SMALL_INPUT_WIDTH = `20%`;

const DisconnectedTextInput = ({
  currentState,
  elementName,
  label,
  large,
  ...props
}: TextInputFieldProps & {
  currentState: TransitionalCareRequest;
  elementName: Exclude<FormField, typeof REQUEST_FORM_FIELDS.REQUEST_SENT_DATE>;
  large?: boolean;
}) => {
  const { dispatch, state } = useRequestListContext();

  const validation = state.validation[elementName];

  return (
    <WithLabel
      bold
      required
      wrapperSx={{
        width: large ? LARGE_INPUT_WIDTH : SMALL_INPUT_WIDTH,
        minWidth: large ? LARGE_INPUT_MIN_WIDTH : SMALL_INPUT_MIN_WIDTH,
      }}
      error={!isValid(validation)}
      label={label}
    >
      <TextInputField
        testId={elementName}
        label={null}
        width="100%"
        marginOverride="0"
        required
        variant="outlined"
        validation={validation}
        style={{
          background: WHITE,
        }}
        value={currentState[elementName]}
        onChange={(value) => {
          dispatch({
            type: ACTION_TYPES.SET_FIELD,
            payload: { field: elementName, value },
          });
        }}
        {...props}
      />
    </WithLabel>
  );
};

const DisconnectedDatePicker = ({
  currentState,
  elementName,
  inputSx,
  label,
  wrapperSx,
  ...props
}: Omit<DateSelectorProps, "onChange"> & {
  currentState: TransitionalCareRequest;
  elementName: typeof REQUEST_FORM_FIELDS.REQUEST_SENT_DATE;
  wrapperSx?: SxProps;
}) => {
  const { dispatch, state } = useRequestListContext();

  const validation = state.validation[elementName];

  return (
    <WithLabel
      bold
      required
      error={!isValid(validation)}
      wrapperSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: SMALL_INPUT_MIN_WIDTH,
        ...(wrapperSx ?? {}),
      }}
      label={label}
    >
      <DatePickerInputField
        {...props}
        elementName={elementName}
        validation={validation}
        required
        textInputVariant="outlined"
        inputSx={{
          background: WHITE,
          width: "100%",
          ...(inputSx ?? {}),
        }}
        value={currentState[elementName] as number}
        onChange={(value) => {
          dispatch({
            type: ACTION_TYPES.SET_FIELD,
            payload: { field: elementName, value },
          });
        }}
      />
    </WithLabel>
  );
};

const DisconnectedTimePicker = ({
  currentState,
  elementName,
  label,
  wrapperSx,
  ...props
}: Omit<TimePresenterProps, "onChange" | "value" | "validation"> & {
  currentState: TransitionalCareRequest;
  elementName: typeof REQUEST_FORM_FIELDS.REQUEST_SENT_TIME;
  wrapperSx?: SxProps;
}) => {
  const { dispatch, state } = useRequestListContext();

  const validation = state.validation[elementName];

  return (
    <WithLabel
      bold
      required
      error={!isValid(validation)}
      wrapperSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: dp(110),
        ...(wrapperSx ?? {}),
      }}
      label={label}
    >
      <TimePickerPresenter
        required
        elementName={elementName}
        validation={validation}
        value={fromUnixTime(currentState[elementName] as number)}
        onChange={(value) => {
          dispatch({
            type: ACTION_TYPES.SET_FIELD,
            payload: { field: elementName, value: getUnixTime(value as Date) },
          });
        }}
        textInputVariant="outlined"
        width="100%"
        {...props}
      />
    </WithLabel>
  );
};

export const DisconnectedSelect = ({
  currentState,
  elementName,
  label,
  ...props
}: Omit<SelectInputProps, "value" | "onChange"> & {
  currentState: TransitionalCareRequest;
  elementName: typeof REQUEST_FORM_FIELDS.REQUEST_STATUS;
}) => {
  const { dispatch, state } = useRequestListContext();

  const validation = state.validation[elementName];

  return (
    <WithLabel
      bold
      required
      label={label}
      error={!isValid(validation)}
      wrapperSx={{
        width: SMALL_INPUT_WIDTH,
      }}
    >
      <SelectInput
        id={elementName}
        label={null}
        required
        {...props}
        size="small"
        validation={validation}
        value={currentState[elementName] as number}
        inputSx={{
          width: "100%",
          background: WHITE,
        }}
        variant="outlined"
        onChange={(value) => {
          dispatch({
            type: ACTION_TYPES.SET_FIELD,
            payload: { field: elementName, value: value as number },
          });
        }}
      />
    </WithLabel>
  );
};

export const EditCard = ({
  displayId,
  isEdit = false,
  request,
}: {
  displayId: number;
  isEdit?: boolean;
  request: TransitionalCareRequest | null;
}) => {
  const { dispatch } = useRequestListContext();
  const translations = useTranslations();
  const selectOptions = useOntologyOptions({
    type: ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS,
  });

  const subFormRef = useRef<HTMLDivElement>(null);

  if (!request) return null;

  return (
    <VerticalLayout
      data-testid={`edit-card-${displayId}`}
      ref={subFormRef}
      width="100%"
      boxSizing="border-box"
      // to allow tab order to flow to the confirm buttons after the input elements
      style={{ flexDirection: "column-reverse" }}
      backgroundColor={WHITE}
      padding={padding(1, 2, 3)}
      margin={margin(2, 0, 1)}
    >
      <VerticalLayout>
        <SectionRow>
          <DisconnectedTextInput
            label={
              translations.patientForms.transitionalCareForm.sectionE.editName
            }
            large
            currentState={request}
            elementName="careprovider_name"
          />
          <DisconnectedTextInput
            label={
              translations.patientForms.transitionalCareForm.sectionE.editStreet
            }
            large
            currentState={request}
            elementName="careprovider_address_address"
          />
        </SectionRow>
        <SectionRow>
          <DisconnectedTextInput
            label={
              translations.patientForms.transitionalCareForm.sectionE
                .editZipCode
            }
            currentState={request}
            elementName="careprovider_address_zipcode"
          />
          <DisconnectedTextInput
            label={
              translations.patientForms.transitionalCareForm.sectionE.editCity
            }
            large
            currentState={request}
            elementName="careprovider_address_city"
          />
        </SectionRow>
        <SectionRow>
          <DisconnectedDatePicker
            label={
              translations.patientForms.transitionalCareForm.sectionE.editDate
            }
            currentState={request}
            elementName="request_sent_date"
          />
          <DisconnectedTimePicker
            label={
              translations.patientForms.transitionalCareForm.sectionE.editTime
            }
            currentState={request}
            elementName="request_sent_time"
          />
          <DisconnectedSelect
            label={
              translations.patientForms.transitionalCareForm.sectionE.editStatus
            }
            currentState={request}
            elementName="request_status"
            options={selectOptions}
          />
        </SectionRow>
      </VerticalLayout>
      <HorizontalLayout aligned width="100%" justify="space-between">
        <Body
          data-testid={`sectionid-${displayId}`}
          fontWeight={FONT_WEIGHT_SEMI_BOLD}
          margin="0"
        >
          {getSectionIdLabel(displayId)}
        </Body>
        <HorizontalLayout
          padding={padding(0, 0.5, 0, 0)}
          aligned
          justify="space-between"
        >
          <RSButton
            id={isEdit ? "save_request" : "add_new_request"}
            loading="na"
            color="primary"
            onClick={() => {
              handleInputFocusOnSubmit(
                (subFormRef.current?.getElementsByTagName("input") ||
                  []) as HTMLInputElement[],
              );
              dispatch({ type: ACTION_TYPES.SAVE });
            }}
            variant="contained"
          >
            {translations.patientForms.transitionalCareForm.sectionE.saveButton}
          </RSButton>
          <CardIconButton
            ariaLabel={
              translations.patientForms.transitionalCareForm.sectionE
                .deleteButtonAria
            }
            onClick={() =>
              dispatch({
                type: ACTION_TYPES.CLOSE_FORM,
              })
            }
            Icon={DeleteOutlinedIcon}
          />
        </HorizontalLayout>
      </HorizontalLayout>
    </VerticalLayout>
  );
};
