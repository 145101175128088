import { margin, padding } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_16, FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import {
  PatientFormSubheading,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const GeneralPractitioner = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading>
        {translations.patientForms.generalForm.sectionI.questionGp}
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpName}
          large
          elementName="general_practitioner_name"
        />
      </SectionRow>
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        fontWeight={FONT_WEIGHT_BOLD}
        margin={margin(3, 0, 0)}
      >
        {translations.patientForms.generalForm.sectionI.subtitleAddressGp}
      </Body>
      <SectionRow customPadding={padding(2, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpStreet}
          large
          elementName="general_practitioner_street"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpZipCode}
          elementName="general_practitioner_zipcode"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpCity}
          large
          elementName="general_practitioner_city"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpPhoneNumber}
          large
          elementName="general_practitioner_phone_number"
        />
      </SectionRow>
    </>
  );
};
