import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_NO } from "core/consts";
import { margin } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const PatientCommunication = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.generalForm.sectionTwo
            .communicationWithPatient
        }
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="communication_in_german_possible"
        label={
          translations.patientForms.generalForm.sectionTwo
            .communicationWithPatient
        }
        formLabelSx={visuallyHidden}
        sideMutation={(_value, mutate) => {
          mutate(null, "communication_other_language");
        }}
        subForms={{
          [PREDICAMENT_STATE_NO]: (
            <TextAreaWithLabel
              elementName="communication_other_language"
              flatModel
              label={
                translations.patientForms.generalForm.sectionTwo
                  .communicationNoGerman
              }
              marginOverride={margin(0)}
            />
          ),
        }}
      />
    </>
  );
};
