import { PREDICAMENT_STATE_YES } from "core/consts";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { padding, sizing } from "ds_legacy/materials/metrics";
import {
  Section,
  SectionRow,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { ICD_CODE_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

export const SectionThree = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvMedicalForm.sectionThree.title}
    >
      <YesNoRadioWithLabel
        elementName="secondary_diagnosis_selected"
        startWithNo
        label={
          translations.patientForms.drvMedicalForm.sectionThree
            .descriptionIllnessess
        }
        sideMutation={(_value, mutate) => {
          mutate(null, "icd_code_2_code");
          mutate(null, "icd_code_3_code");
          mutate(null, "secondary_diagnosis");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout
              width="100%"
              gap={sizing(1)}
              padding={padding(0, 0, 1)}
            >
              <SectionRow>
                <TextInputWithLabel
                  bold
                  flatModel
                  label={
                    translations.patientForms.drvMedicalForm.sectionThree
                      .diagnosisIcd
                  }
                  large
                  elementName="icd_code_2_code"
                  characterLimit={ICD_CODE_CHARACTER_LIMIT}
                />
              </SectionRow>
              <SectionRow>
                <TextInputWithLabel
                  bold
                  flatModel
                  label={
                    translations.patientForms.drvMedicalForm.sectionThree
                      .diagnosisIcd
                  }
                  large
                  elementName="icd_code_3_code"
                  characterLimit={ICD_CODE_CHARACTER_LIMIT}
                />
              </SectionRow>
              <SectionRow>
                <TextInputWithLabel
                  bold
                  flatModel
                  label={
                    translations.patientForms.drvMedicalForm.sectionThree
                      .nyhaStages
                  }
                  large
                  elementName="secondary_diagnosis"
                />
              </SectionRow>
            </VerticalLayout>
          ),
        }}
      />
    </Section>
  );
};
