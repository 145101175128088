import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { QueryProgress } from "core/types";
import RSButton, { RSButtonProps } from "ds_legacy/components/RSButton";
import { TEXT_DARK_SECONDARY } from "ds_legacy/materials/colors";
import { Subheading } from "ds_legacy/materials/typography";
import React from "react";
import { useTranslations } from "translations";

export type ConfirmationDialogProps = {
  cancelText?: string;
  componentContent?: React.ReactNode;
  confirmButtonProps?: Partial<Omit<RSButtonProps, "onClick">>;
  content?: string;
  id?: string;
  nodeContent?: React.ReactNode;
  onCancel: () => void;
  onSubmit: () => void;
  opened: boolean;
  queryProgress?: QueryProgress;
  submitText?: string;
  title: React.ReactNode | string;
};

const ConfirmationDialog = ({
  cancelText,
  componentContent,
  confirmButtonProps,
  content,
  id,
  nodeContent,
  onCancel,
  onSubmit,
  opened,
  queryProgress,
  submitText,
  title,
}: ConfirmationDialogProps) => {
  const translations = useTranslations();

  return (
    <Dialog
      id={id}
      data-testid={id}
      open={opened}
      fullWidth
      PaperProps={{
        "aria-modal": "true",
      }}
    >
      <DialogTitle>{title ?? ""}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      {componentContent && <DialogContent>{componentContent}</DialogContent>}
      {nodeContent && (
        <DialogContent>
          <Subheading style={{ color: TEXT_DARK_SECONDARY }}>
            {nodeContent}
          </Subheading>
        </DialogContent>
      )}
      <DialogActions>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onCancel}
          variant="text"
        >
          {cancelText || translations.actions.cancel}
        </RSButton>
        <RSButton
          color="primary"
          id="confirm"
          loading={queryProgress || "na"}
          onClick={onSubmit}
          variant="text"
          {...confirmButtonProps}
        >
          {submitText || translations.actions.confirm}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
