import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { GUARDIAN } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_16, FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import {
  PatientFormSubheading,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const Guardian = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: GUARDIAN.CARER,
      value: GUARDIAN.CARER,
      label: translations.patientForms.generalForm.sectionI.optionCarer,
    },
    {
      id: GUARDIAN.HEALTH_CARE_REP,
      value: GUARDIAN.HEALTH_CARE_REP,
      label: translations.patientForms.generalForm.sectionI.optionHealthCareRep,
    },
    {
      id: GUARDIAN.LEGAL_REP,
      value: GUARDIAN.LEGAL_REP,
      label: translations.patientForms.generalForm.sectionI.optionLegalRep,
    },
  ];

  return (
    <>
      <PatientFormSubheading>
        {translations.patientForms.generalForm.sectionI.questionGuardian}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="insured_details_guardian_type"
        label={
          translations.patientForms.generalForm.sectionI
            .accessibilityGuardianType
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioGroupSx={{ flexDirection: "row", paddingTop: sizing(1) }}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpName}
          large
          elementName="insured_details_guardian_last_first_name"
        />
      </SectionRow>
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        fontWeight={FONT_WEIGHT_BOLD}
        margin={margin(3, 0, 0)}
      >
        {translations.patientForms.generalForm.sectionI.subtitleAddressGp}
      </Body>
      <SectionRow customPadding={padding(2, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpStreet}
          large
          elementName="insured_details_guardian_street_house_number"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpZipCode}
          elementName="insured_details_guardian_zipcode"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpCity}
          large
          elementName="insured_details_guardian_city"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionI.gpPhoneNumber}
          large
          elementName="insured_details_guardian_phone"
        />
      </SectionRow>
    </>
  );
};
