import Translations from "translations/types";

const REVERSE_SEARCH_PAGE_EXISTING_KEY = "reverse_searches_existing" as const;

const REVERSE_SEARCH_EXISTING_STEPS = {
  EXISTING_SEARCH: "existing_search",
  ADD_NEW_SEARCH: "add_new_search",
} as const;

export const REVERSE_SEARCH_PAGE_EXISTING = {
  key: REVERSE_SEARCH_PAGE_EXISTING_KEY,
  steps: {
    [REVERSE_SEARCH_EXISTING_STEPS.EXISTING_SEARCH]: {
      key: REVERSE_SEARCH_EXISTING_STEPS.EXISTING_SEARCH,
      intro: (t: Translations) => t.tours.reverseSearch.stepE.introCapacityMode,
      title: (t: Translations) => t.tours.reverseSearch.stepF.title,
      position: "left",
      step: 1,
    },
    [REVERSE_SEARCH_EXISTING_STEPS.ADD_NEW_SEARCH]: {
      key: REVERSE_SEARCH_EXISTING_STEPS.ADD_NEW_SEARCH,
      intro: (t: Translations) => t.tours.reverseSearch.stepG.intro,
      title: (t: Translations) => t.tours.reverseSearch.stepG.title,
      position: "top",
      step: 2,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  scrollTo: "tooltip",
} as const;
