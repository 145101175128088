import { isNewTheme } from "core/model/utils/featureFlags";
import { LockIcon } from "ds/icons";
import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_DARK, ICON_LIGHT_GREY } from "ds_legacy/materials/colors";
import React from "react";

export function LockSecurePin({
  style,
  tooltip,
}: {
  style?: React.CSSProperties;
  tooltip?: string;
}) {
  const Pin = (
    <LockIcon
      style={{ color: isNewTheme ? ICON_DARK : ICON_LIGHT_GREY, ...style }}
      size={style?.fontSize}
    />
  );

  return (
    <Tooltip title={tooltip} tooltipTextAlign="center">
      {Pin}
    </Tooltip>
  );
}
