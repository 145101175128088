import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES } from "core/consts";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionTen = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionNine.title}>
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionNine.subtitle}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="unemployment_benefit_type_2"
        label={translations.patientForms.drvGeneralForm.sectionNine.subtitle}
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "jobcenter_name");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout padding={padding(0, 0, 1)}>
              <TextInputWithLabel
                elementName="jobcenter_name"
                flatModel
                label={
                  translations.patientForms.drvGeneralForm.sectionNine.jobCenter
                }
                large
                marginOverride={margin(0)}
              />
            </VerticalLayout>
          ),
        }}
      />
    </Section>
  );
};
