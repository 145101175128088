import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ONTOLOGY_EMPLOYMENT_STATUS } from "core/model/utils/ontologies";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { padding, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
} from "dsl/ecosystems/PatientForms/components";
import { OntologyRadioGroupV2 } from "dsl/molecules/OntologyRadioGroup";
import { useTranslations } from "translations";

export const SectionFive = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionFour.title}>
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionFour.subtitle}
      </PatientFormSubheading>
      <OntologyRadioGroupV2
        elementName="employment_status"
        label={translations.patientForms.drvGeneralForm.sectionFour.subtitle}
        type={ONTOLOGY_EMPLOYMENT_STATUS}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
    </Section>
  );
};
