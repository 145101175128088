import {
  CARELEVEL_FIVE,
  CARELEVEL_FOUR,
  CARELEVEL_NONE,
  CARELEVEL_ONE,
  CARELEVEL_THREE,
  CARELEVEL_TWO,
  DRV_TRAVEL_TYPES,
  OUTPATIENT_NOT_POSSIBLE_REASONS,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
} from "core/consts";
import { ONTOLOGY_CARE_LEVEL } from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import ConnectedCheckboxGroup, {
  CheckboxGroupProps,
} from "ds_legacy/components/CheckboxGroup";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  Section,
  SelectWithLabel,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { INFECTIONS_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

export const SectionFive = () => {
  const translations = useTranslations();
  const getOntology = useGetOntology();

  const patientCapabilityTravelOptions: CheckboxGroupProps["items"] = [
    {
      id: DRV_TRAVEL_TYPES.PUBLIC,
      label: translations.patientForms.drvMedicalForm.sectionFive.travelPublic,
    },
    {
      id: DRV_TRAVEL_TYPES.CAR,
      label: translations.patientForms.drvMedicalForm.sectionFive.travelCar,
    },
  ];

  const outpatientReasonOptions: CheckboxGroupProps["items"] = [
    {
      id: OUTPATIENT_NOT_POSSIBLE_REASONS.ACCOMPANYING_PERSON,
      label:
        translations.patientForms.drvMedicalForm.sectionFive.accompanyingPerson,
    },
    {
      id: OUTPATIENT_NOT_POSSIBLE_REASONS.TAXI,
      label: translations.patientForms.drvMedicalForm.sectionFive.taxi,
    },
    {
      id: OUTPATIENT_NOT_POSSIBLE_REASONS.AMBULANCE,
      label: translations.patientForms.drvMedicalForm.sectionFive.lyingDown,
    },
  ];

  const carelevels = [
    CARELEVEL_NONE,
    CARELEVEL_ONE,
    CARELEVEL_TWO,
    CARELEVEL_THREE,
    CARELEVEL_FOUR,
    CARELEVEL_FIVE,
  ];

  const carelevelSelectOptions: SelectOption[] = carelevels.map(
    (carelevel) => ({
      id: carelevel,
      value: carelevel,
      label: getOntology({ type: ONTOLOGY_CARE_LEVEL, key: carelevel }),
    }),
  );

  return (
    <Section title={translations.patientForms.drvMedicalForm.sectionFive.title}>
      <YesNoRadioWithLabel
        startWithNo
        elementName="eat_without_help"
        label={translations.patientForms.drvMedicalForm.sectionFive.eat}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="wash_and_dress_without_help"
        label={translations.patientForms.drvMedicalForm.sectionFive.wash}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="mobility_walk_without_help"
        label={translations.patientForms.drvMedicalForm.sectionFive.move}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="wound_closed"
        label={translations.patientForms.drvMedicalForm.sectionFive.wound}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="infections_selected"
        label={translations.patientForms.drvMedicalForm.sectionFive.germs}
        sideMutation={(_value, mutate) => {
          mutate(null, "infections");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextInputWithLabel
              elementName="infections"
              flatModel
              large
              characterLimit={INFECTIONS_CHARACTER_LIMIT}
              label={
                translations.patientForms.drvMedicalForm.sectionFive
                  .germsDescription
              }
              marginOverride={margin(0, 0, 1)}
            />
          ),
        }}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="urinary_incontinence"
        label={
          translations.patientForms.drvMedicalForm.sectionFive
            .urinalIncontinence
        }
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="needs_catheter_selected"
        label={translations.patientForms.drvMedicalForm.sectionFive.catheters}
        sideMutation={(_value, mutate) => {
          mutate(null, "needs_catheter");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <TextAreaWithLabel
              elementName="needs_catheter"
              flatModel
              label={
                translations.patientForms.drvMedicalForm.sectionFive
                  .cathetersDescription
              }
              marginOverride={margin(0, 0, 1)}
            />
          ),
        }}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="fecal_incontinence"
        label={
          translations.patientForms.drvMedicalForm.sectionFive.incontinence
        }
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="mobility_wheel_chair"
        label={translations.patientForms.drvMedicalForm.sectionFive.wheelchair}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="orientation_disoriented_selected"
        label={translations.patientForms.drvMedicalForm.sectionFive.orientation}
        sideMutation={(_value, mutate) => {
          mutate(null, "orientation_disoriented");
        }}
        subForms={{
          [PREDICAMENT_STATE_NO]: (
            <TextAreaWithLabel
              elementName="orientation_disoriented"
              flatModel
              label={
                translations.patientForms.drvMedicalForm.sectionFive
                  .orientationDescription
              }
              marginOverride={margin(0, 0, 1)}
            />
          ),
        }}
      />
      <YesNoRadioWithLabel
        startWithNo
        elementName="carelevel_selected"
        label={
          translations.patientForms.drvMedicalForm.sectionFive.carelevelLabel
        }
        sideMutation={(_value, mutate) => {
          mutate(null, "carelevel");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <SelectWithLabel
              elementName="carelevel"
              flatModel
              options={carelevelSelectOptions}
              label={
                translations.patientForms.drvMedicalForm.sectionFive
                  .carelevelDescription
              }
              wrapperSx={{ width: "100%" }}
            />
          ),
        }}
      />
      <ConnectedCheckboxGroup
        elementName="transport_type"
        formGroupStyle={{ gap: sizing(1) }}
        label={translations.patientForms.drvMedicalForm.sectionFive.travel}
        items={patientCapabilityTravelOptions}
      />
      <ConnectedCheckboxGroup
        elementName="outpatient_not_possible_reason"
        formGroupStyle={{ gap: sizing(1) }}
        label={
          translations.patientForms.drvMedicalForm.sectionFive.medicalReason
        }
        items={outpatientReasonOptions}
      />
    </Section>
  );
};
