import { Section } from "dsl/ecosystems/PatientForms/components";
import { Complications } from "./Complications";
import { FunctionDiagnosis } from "./FunctionDiagnosis";
import { PerformedTreatments } from "./PerformedTreatments";
import { SingerProfile } from "./SingerProfile";

export const SectionTwo = ({
  onChange,
}: {
  onChange: (value: any, statePath: string) => void;
}) => {
  return (
    <Section>
      <FunctionDiagnosis />
      <PerformedTreatments />
      <Complications />
      <SingerProfile onChange={onChange} />
    </Section>
  );
};
