import { visuallyHidden } from "@mui/utils";
import {
  PatientFormSubheading,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const EarlyRehabMeasures = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionOne.earlyRehabMeasures}
      </PatientFormSubheading>

      <YesNoRadioWithLabel
        elementName="early_rehabilitation_measures"
        label={
          translations.patientForms.medicalForm.sectionOne.earlyRehabMeasures
        }
        formLabelSx={visuallyHidden}
        startWithNo
      />
    </>
  );
};
