import {
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const HospitalInformation = () => {
  const translations = useTranslations();

  return (
    <>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionTwo.hospitalName}
          large
          elementName="hospital_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.sectionTwo
              .hospitalAddressStreet
          }
          large
          elementName="hospital_address"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.sectionTwo.hospitalAddressCity
          }
          large
          elementName="hospital_zipcode_city"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.generalForm.sectionTwo.patientId}
          large
          elementName="case_id"
        />
      </SectionRow>
    </>
  );
};
