import { visuallyHidden } from "@mui/utils";
import { REHAB_TRIGGERING_EVENT } from "core/consts";
import ConnectedCheckboxGroup, {
  CheckboxGroupProps,
} from "ds_legacy/components/CheckboxGroup";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  LARGE_INPUT_WIDTH,
  PatientFormSubheading,
  SMALL_INPUT_MIN_WIDTH,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

export const IntroSection = () => {
  const translations = useTranslations();

  const options: CheckboxGroupProps["items"] = [
    {
      id: REHAB_TRIGGERING_EVENT.SURGERY,
      label: translations.patientForms.drvMedicalForm.sectionZero.surgery,
    },
    {
      id: REHAB_TRIGGERING_EVENT.ACUTE_EVENT,
      label: translations.patientForms.drvMedicalForm.sectionZero.acuteEvent,
    },
    {
      id: REHAB_TRIGGERING_EVENT.RADIATION,
      label: translations.patientForms.drvMedicalForm.sectionZero.radiation,
    },
    {
      id: REHAB_TRIGGERING_EVENT.ANTINEOPLASTIC_THERAPY,
      label:
        translations.patientForms.drvMedicalForm.sectionZero
          .antineoplasticTherapy,
    },
    {
      id: REHAB_TRIGGERING_EVENT.NOT_APPLICABLE,
      label: translations.patientForms.drvMedicalForm.sectionZero.notApplicable,
    },
    {
      id: REHAB_TRIGGERING_EVENT.ACCIDENT_WORK,
      label: translations.patientForms.drvMedicalForm.sectionZero.accidentWork,
    },
    {
      id: REHAB_TRIGGERING_EVENT.THIRD_PARTY_DAMAGE,
      label:
        translations.patientForms.drvMedicalForm.sectionZero.thirdPartyDamage,
    },
  ];

  return (
    <Section>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.insuranceNumber
          }
          large
          elementName="statutory_pension_insurance_number"
          characterLimit={PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT}
        />
      </SectionRow>
      <PatientFormSubheading style={{ margin: margin(0, 0, -1) }}>
        {
          translations.patientForms.drvMedicalForm.sectionZero
            .subtitleHospitalAddress
        }
      </PatientFormSubheading>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.addressHospital}
          large
          elementName="hospital_name_address"
          labelWrapperSx={{ width: "100%" }}
        />
      </SectionRow>
      <PatientFormSubheading style={{ margin: margin(0, 0, -1) }}>
        {
          translations.patientForms.drvMedicalForm.sectionZero
            .subtitleContactPerson
        }
      </PatientFormSubheading>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero
              .contactPersonInformation
          }
          large
          elementName="social_worker_name_number"
          labelWrapperSx={{ width: "100%" }}
        />
      </SectionRow>
      <PatientFormSubheading style={{ margin: margin(0, 0, -1) }}>
        {translations.patientForms.drvMedicalForm.sectionZero.subtitleAhb}
      </PatientFormSubheading>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.addressAhb
          }
          large
          elementName="preferred_rehab_clinic"
          labelWrapperSx={{ width: "100%" }}
        />
      </SectionRow>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.drvMedicalForm.sectionZero.typeOfProcedure}
      </PatientFormSubheading>
      <CheckboxInputField
        elementName="pension_procedure_direct"
        label={
          translations.patientForms.drvMedicalForm.sectionZero.directDischarge
        }
      />
      <CheckboxInputField
        elementName="pension_procedure_after_examination"
        label={
          translations.patientForms.drvMedicalForm.sectionZero
            .instructionAfterExamination
        }
      />
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.drvMedicalForm.sectionZero
            .subtitleSuggestedRehab
        }
      </PatientFormSubheading>
      <CheckboxInputField
        elementName="full_reha"
        label={translations.patientForms.drvMedicalForm.sectionZero.static}
      />
      <CheckboxInputField
        elementName="day_reha"
        label={translations.patientForms.drvMedicalForm.sectionZero.mobile}
      />
      <PatientFormSubheading style={{ margin: margin(0, 0, -1) }}>
        {
          translations.patientForms.drvMedicalForm.sectionZero
            .subtitlePatientInformation
        }
      </PatientFormSubheading>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.patientName
          }
          large
          elementName="patient_name"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero
              .dateBirthPatient
          }
          elementName="patient_birthdate"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.addressPatient
          }
          large
          elementName="patient_street_housenumber"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.zipCodePatient
          }
          elementName="patient_zipcode"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionZero.city}
          large
          elementName="patient_city"
        />
      </SectionRow>
      <PatientFormSubheading style={{ margin: margin(0, 0, -1) }}>
        {translations.patientForms.drvMedicalForm.sectionZero.subtitleHospital}
      </PatientFormSubheading>
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.intakeDate
          }
          elementName="admission_date"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero.ahbEventDate
          }
          elementName="rehab_triggering_event_date"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
      <SectionRow>
        <ConnectedCheckboxGroup
          elementName="rehab_triggering_event"
          formGroupStyle={{ gap: sizing(1) }}
          label={
            translations.patientForms.drvMedicalForm.sectionZero
              .ahbEventCheckboxGroupLabel
          }
          labelStyle={visuallyHidden}
          items={options}
        />
      </SectionRow>
      <SectionRow>
        <DatePickerWithLabel
          bold
          elementName="discharge_date"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          label={
            translations.patientForms.drvMedicalForm.sectionZero.dischargeDate
          }
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
        <DatePickerWithLabel
          bold
          elementName="transfer_date"
          label={
            translations.patientForms.drvMedicalForm.sectionZero.ahbStartDate
          }
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
    </Section>
  );
};
