import Translations from "translations/types";

export const RECEIVER_SETTINGS_KEY = "receiver_settings" as const;

const RECEIVER_SETTINGS_STEPS = {
  SERVICES: "services",
  REVERSE_SEARCH: "reverse_search",
  CAPACITY: "capacity",
} as const;

export const RECEIVER_SETTINGS = {
  key: RECEIVER_SETTINGS_KEY,
  steps: {
    [RECEIVER_SETTINGS_STEPS.SERVICES]: {
      key: RECEIVER_SETTINGS_STEPS.SERVICES,
      intro: (t: Translations) => t.tours.receiverSettings.stepA.intro,
      title: (t: Translations) => t.tours.receiverSettings.stepA.title,
      step: 1,
    },
    [RECEIVER_SETTINGS_STEPS.REVERSE_SEARCH]: {
      key: RECEIVER_SETTINGS_STEPS.REVERSE_SEARCH,
      intro: (t: Translations) => t.tours.receiverSettings.stepB.intro,
      title: (t: Translations) => t.tours.receiverSettings.stepB.title,
      step: 2,
    },
    [RECEIVER_SETTINGS_STEPS.CAPACITY]: {
      key: RECEIVER_SETTINGS_STEPS.CAPACITY,
      intro: (t: Translations) => t.tours.receiverSettings.stepC.intro,
      title: (t: Translations) => t.tours.receiverSettings.stepC.title,
      step: 3,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
} as const;
