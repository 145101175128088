import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  REQUESTED_REHAB_SERVICE,
  REQUESTED_REHAB_SERVICE_TYPE,
} from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { sizing } from "ds_legacy/materials/metrics";
import { Section } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionOne = () => {
  const translations = useTranslations();

  const subFormOptions: RadioOptionV2[] = [
    {
      id: REQUESTED_REHAB_SERVICE_TYPE.OUTPATIENT,
      value: REQUESTED_REHAB_SERVICE_TYPE.OUTPATIENT,
      label: translations.patientForms.drvGeneralForm.sectionOne.outpatient,
    },
    {
      id: REQUESTED_REHAB_SERVICE_TYPE.INPATIENT,
      value: REQUESTED_REHAB_SERVICE_TYPE.INPATIENT,
      label: translations.patientForms.drvGeneralForm.sectionOne.inpatient,
    },
  ];

  const options: RadioOptionV2[] = [
    {
      id: REQUESTED_REHAB_SERVICE.INSURED,
      value: REQUESTED_REHAB_SERVICE.INSURED,
      label:
        translations.patientForms.drvGeneralForm.sectionOne.rehabForInsured,
      subForm: (
        <RadioGroupV2
          elementName="requested_rehab_service_type_insured"
          flatModel
          formControlLabelSx={{
            paddingBottom: sizing(0.5),
            marginLeft: sizing(2),
          }}
          formLabelSx={visuallyHidden as SxProps}
          label={
            translations.patientForms.medicalForm.sectionSix.taxiOptionLabel
          }
          options={subFormOptions}
        />
      ),
    },
    {
      id: REQUESTED_REHAB_SERVICE.RELATIVES,
      value: REQUESTED_REHAB_SERVICE.RELATIVES,
      label:
        translations.patientForms.drvGeneralForm.sectionOne.oncologicalRehab,
      subForm: (
        <RadioGroupV2
          elementName="requested_rehab_service_type_relatives"
          flatModel
          formControlLabelSx={{
            paddingBottom: sizing(0.5),
            marginLeft: sizing(2),
          }}
          formLabelSx={visuallyHidden as SxProps}
          label={
            translations.patientForms.medicalForm.sectionSix.taxiOptionLabel
          }
          options={subFormOptions}
        />
      ),
    },
  ];

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionOne.title}>
      <RadioGroupV2
        elementName="requested_rehab_service"
        label={translations.patientForms.drvGeneralForm.sectionOne.title}
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(value, mutate) => {
          if (value === REQUESTED_REHAB_SERVICE.INSURED) {
            mutate(null, "requested_rehab_service_type_relatives");
          } else {
            mutate(null, "requested_rehab_service_type_insured");
          }
        }}
      />
    </Section>
  );
};
