import { margin } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { YesNoRadioWithLabel } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const QuestionCareNeeded = () => {
  const translations = useTranslations();

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.generalForm.sectionI.titleNeedForCare}
      </Subheading>
      <YesNoRadioWithLabel
        elementName="insured_details_application_insurance_benefits"
        label={
          translations.patientForms.generalForm.sectionI.questionNeedForCare
        }
      />
    </>
  );
};
