import { padding } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

export const SectionZero = () => {
  const translations = useTranslations();

  return (
    <Section>
      <SectionRow style={{ flexWrap: "nowrap" }}>
        <TextInputWithLabel
          bold
          characterLimit={PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT}
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .pensionInsuranceNumber
          }
          labelWrapperSx={{ width: "30%" }}
          elementName="statutory_pension_insurance_number"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .addressPensionInsurance
          }
          labelWrapperSx={{ width: "70%" }}
          elementName="pension_insurance_company_name"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .addressHealthInsurance
          }
          labelWrapperSx={{ width: "100%" }}
          elementName="insurance_company_name_address"
        />
      </SectionRow>
      <PatientFormSubheading>
        {
          translations.patientForms.drvGeneralForm.insuranceInformation
            .subtitleHospital
        }
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .addressHospital
          }
          labelWrapperSx={{ width: "100%" }}
          elementName="hospital_name_address"
        />
      </SectionRow>
      <PatientFormSubheading>
        {
          translations.patientForms.drvGeneralForm.insuranceInformation
            .subtitleSocialWorker
        }
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .informationSocialWorker
          }
          labelWrapperSx={{ width: "100%" }}
          elementName="social_worker_name_number"
        />
      </SectionRow>
      <PatientFormSubheading>
        {
          translations.patientForms.drvGeneralForm.insuranceInformation
            .subtitleRehabClinic
        }
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.insuranceInformation
              .informationAhbFacility
          }
          labelWrapperSx={{ width: "100%" }}
          elementName="preferred_rehab_clinic"
        />
      </SectionRow>
    </Section>
  );
};
