import { PREDICAMENT_STATE_YES } from "core/consts";
import { ONTOLOGY_INSURANCE_TYPE } from "core/model/utils/ontologies";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import {
  DatePickerWithLabel,
  LARGE_INPUT_MIN_WIDTH,
  LARGE_INPUT_WIDTH,
  Section,
  SectionRow,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { OntologyRadioGroupV2 } from "dsl/molecules/OntologyRadioGroup";
import { useTranslations } from "translations";

export const SectionSeven = () => {
  const translations = useTranslations();

  return (
    <Section
      title={
        translations.patientForms.drvGeneralForm.sectionSix.insuranceInformation
      }
    >
      <SectionRow customPadding={padding(0, 0, 2)}>
        <TextInputWithLabel
          label={
            translations.patientForms.drvGeneralForm.sectionSix.insuranceName
          }
          large
          elementName="insurance_name"
        />
      </SectionRow>
      <OntologyRadioGroupV2
        elementName="insurance_type"
        type={ONTOLOGY_INSURANCE_TYPE}
        label={
          translations.patientForms.drvGeneralForm.sectionSix.patientInsurance
        }
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={{
          padding: padding(1, 0),
          fontSize: FONT_SIZE_14,
        }}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
      <YesNoRadioWithLabel
        elementName="patient_unable_to_work_before_hospitalization"
        label={
          translations.patientForms.drvGeneralForm.sectionSix
            .subtitleHospitalStay
        }
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "patient_unable_to_work_before_hospitalization_date");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <DatePickerWithLabel
              bold
              elementName="patient_unable_to_work_before_hospitalization_date"
              flatModel
              inputSx={{
                width: LARGE_INPUT_WIDTH,
                minWidth: LARGE_INPUT_MIN_WIDTH,
              }}
              label={
                translations.patientForms.drvGeneralForm.sectionSix.sinceDate
              }
            />
          ),
        }}
      />
    </Section>
  );
};
