import { PREDICAMENT_STATE_YES } from "core/consts";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  LARGE_INPUT_WIDTH,
  Section,
  SectionRow,
  SMALL_INPUT_MIN_WIDTH,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionTwo = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvMedicalForm.sectionTwo.title}>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextAreaWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionTwo
              .descriptionComplications
          }
          elementName="complications"
          marginOverride={margin(0)}
        />
      </SectionRow>
      <SectionRow>
        <TextAreaWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionTwo
              .descriptionTherapies
          }
          elementName="current_therapy"
          marginOverride={margin(0)}
        />
      </SectionRow>
      <YesNoRadioWithLabel
        elementName="other_measures"
        startWithNo
        label={
          translations.patientForms.drvMedicalForm.sectionTwo.otherMeasures
        }
        sideMutation={(_value, mutate) => {
          mutate(null, "further_therapeutic_diagnostic_measures_description");
          mutate(null, "further_therapeutic_diagnostic_measures_from");
          mutate(null, "further_therapeutic_diagnostic_measures_to");
          mutate(null, "further_therapeutic_diagnostic_measures_finished_on");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <VerticalLayout
              width="100%"
              gap={sizing(1)}
              padding={padding(0, 0, 1)}
            >
              <TextAreaWithLabel
                elementName="further_therapeutic_diagnostic_measures_description"
                flatModel
                label={
                  translations.patientForms.drvMedicalForm.sectionTwo
                    .descriptionMeasures
                }
                marginOverride={margin(0)}
              />
              <SectionRow>
                <DatePickerWithLabel
                  bold
                  elementName="further_therapeutic_diagnostic_measures_from"
                  flatModel
                  inputSx={{
                    width: "100%",
                    minWidth: SMALL_INPUT_MIN_WIDTH,
                  }}
                  wrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  label={
                    translations.patientForms.drvMedicalForm.sectionTwo.fromDate
                  }
                />
                <DatePickerWithLabel
                  bold
                  elementName="further_therapeutic_diagnostic_measures_to"
                  flatModel
                  inputSx={{
                    width: "100%",
                    minWidth: SMALL_INPUT_MIN_WIDTH,
                  }}
                  wrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  label={
                    translations.patientForms.drvMedicalForm.sectionTwo
                      .untilDate
                  }
                />
                <DatePickerWithLabel
                  bold
                  elementName="further_therapeutic_diagnostic_measures_finished_on"
                  flatModel
                  inputSx={{
                    width: "100%",
                    minWidth: SMALL_INPUT_MIN_WIDTH,
                  }}
                  wrapperSx={{ width: LARGE_INPUT_WIDTH }}
                  label={
                    translations.patientForms.drvMedicalForm.sectionTwo
                      .endsOnDate
                  }
                />
              </SectionRow>
            </VerticalLayout>
          ),
        }}
      />
    </Section>
  );
};
