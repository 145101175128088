import TextField, { TextFieldProps } from "@mui/material/TextField";
import { getCharacterCounter } from "core/model/utils/strings";
import { GREY_600 } from "ds_legacy/materials/colors";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import React from "react";
import { FormElement, FormElementProps, isValid } from "react-forms-state";
import { useTranslations } from "translations";
import { getHelperText } from "../Validation";

export type TextAreaProps = Pick<
  TextFieldProps,
  | "disabled"
  | "fullWidth"
  | "label"
  | "maxRows"
  | "minRows"
  | "multiline"
  | "onKeyPress"
  | "placeholder"
  | "required"
  | "rows"
  | "id"
  | "sx"
  | "type"
  | "value"
  | "value"
  | "helperText"
  | "error"
> & {
  ariaLabel?: string;
  characterLimit?: number;
  elementName?: string;
  endAdornment?: React.ReactNode;
  flatModel?: boolean;
  hideError?: boolean;
  margin?: "dense" | "none" | "normal";
  marginOverride?: string;
  maxWidth?: string;
  onChange?: (value: any, modelPath?: string) => void;
  paddingArea?: string;
  startAdornment?: React.ReactNode;
  validation?: boolean;
  width?: string;
};

export default function TextArea({
  ariaLabel,
  characterLimit,
  disabled,
  elementName,
  endAdornment,
  error,
  fullWidth,
  helperText,
  hideError = false,
  id,
  label,
  margin,
  marginOverride,
  maxRows,
  maxWidth,
  minRows,
  multiline = true,
  onChange,
  onKeyPress,
  placeholder,
  required,
  rows,
  startAdornment,
  sx,
  type,
  validation,
  value,
  width,
}: TextAreaProps) {
  const translations = useTranslations();

  const customHelperText = getHelperText({
    hasCustomValidation: true,
    translations,
    validation,
  });

  const characterLimitCounter = getCharacterCounter({
    characterLimit,
    value,
    translations,
  });

  return (
    <TextField
      disabled={disabled}
      error={hideError ? false : error ?? !isValid(validation)}
      fullWidth={fullWidth}
      id={id}
      InputLabelProps={{
        style: {
          background: "#fff",
          marginRight: sizing(3),
          paddingRight: sizing(0.5),
        },
      }}
      InputProps={{
        style: {
          fontSize: FONT_SIZE_14,
          background: "#fff",
        },
        startAdornment,
        endAdornment,
      }}
      inputProps={{
        style: { padding: padding(1.5) },
        "aria-label": ariaLabel,
        maxLength: characterLimit,
      }}
      label={label}
      helperText={
        (hideError ? undefined : customHelperText) ??
        helperText ??
        characterLimitCounter
      }
      margin={margin || "normal"}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={elementName}
      onChange={(e) => onChange?.(e.target.value)}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      required={required}
      rows={rows}
      sx={{
        width: fullWidth ? "100%" : width || "auto",
        maxWidth: maxWidth || "auto",
        margin: marginOverride || undefined,
        ...sx,
        "& textarea:first-of-type::placeholder": {
          color: GREY_600,
          opacity: 1,
        },
        "& .MuiInputBase-root": {
          padding: padding(0.5),
        },
      }}
      type={type}
      value={value === null ? "" : value}
      variant="outlined"
    />
  );
}

export const ConnectedTextarea = FormElement()<
  TextAreaProps & FormElementProps
>(TextArea);
