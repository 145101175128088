import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { IntroSection } from "./FormSections/IntroSection";
import { SectionFive } from "./FormSections/SectionFive";
import { SectionFour } from "./FormSections/SectionFour";
import { SectionOne } from "./FormSections/SectionOne";
import { SectionSeven } from "./FormSections/SectionSeven";
import { SectionSix } from "./FormSections/SectionSix";
import { SectionThree } from "./FormSections/SectionThree";
import { SectionTwo } from "./FormSections/SectionTwo";

export function MedicalFormPensionPresenter() {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <IntroSection />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwo />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThree />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFour />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFive />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSix />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSeven />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
