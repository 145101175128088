import { Section } from "dsl/ecosystems/PatientForms/components";
import { QuestionCareNeeded } from "./CareNeedDetails";
import { GeneralPractitioner } from "./GeneralPractitioner";
import { Guardian } from "./Guardian";
import { HomeCare } from "./HomeCare";
import { LivingSituation } from "./LivingSituation";
import { PensionApplication } from "./PensionApplication";
import { SpecialRequirements } from "./SpecialRequirements";

export const SectionOne = () => {
  return (
    <Section>
      <QuestionCareNeeded />
      <PensionApplication />
      <LivingSituation />
      <HomeCare />
      <GeneralPractitioner />
      <Guardian />
      <SpecialRequirements />
    </Section>
  );
};
