import Translations from "translations/types";

const REVERSE_SEARCH_PAGE_KEY = "reverse_search" as const;

const REVERSE_SEARCH_STEPS = {
  TYPE_OF_PATIENT: "type_of_patient",
  SAVE: "save",
  ADD_NEW: "add_new",
} as const;

export const REVERSE_SEARCH_PAGE = {
  key: REVERSE_SEARCH_PAGE_KEY,
  steps: {
    [REVERSE_SEARCH_STEPS.TYPE_OF_PATIENT]: {
      key: REVERSE_SEARCH_STEPS.TYPE_OF_PATIENT,
      intro: (t: Translations) => t.tours.reverseSearch.stepC.intro,
      title: (t: Translations) => t.tours.reverseSearch.stepC.title,
      position: "left",
      step: 1,
    },
    [REVERSE_SEARCH_STEPS.SAVE]: {
      key: REVERSE_SEARCH_STEPS.SAVE,
      title: (t: Translations) => t.tours.reverseSearch.stepD.title,
      intro: (t: Translations) => t.tours.reverseSearch.stepD.intro,
      position: "top",
      step: 2,
    },
    [REVERSE_SEARCH_STEPS.ADD_NEW]: {
      key: REVERSE_SEARCH_STEPS.ADD_NEW,
      title: (t: Translations) => t.tours.reverseSearch.stepE.title,
      intro: (t: Translations) => t.tours.reverseSearch.stepE.introCapacityMode,
      position: "left",
      step: 3,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
} as const;
