import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { OntologyRadioGroupV2 } from "dsl/molecules/OntologyRadioGroup";
import { useTranslations } from "translations";

export const SectionFour = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionThree.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionThree.subtitle}
      </PatientFormSubheading>
      <OntologyRadioGroupV2
        elementName="marital_status"
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        type="maritalStatus"
        label={translations.patientForms.drvGeneralForm.sectionThree.subtitle}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
      <PatientFormSubheading>
        {
          translations.patientForms.drvGeneralForm.sectionThree
            .subtitleProfession
        }
      </PatientFormSubheading>
      <TextAreaWithLabel
        elementName="last_employment"
        label={
          translations.patientForms.drvGeneralForm.sectionThree
            .fieldLabelProfession
        }
        marginOverride={margin(0)}
      />
    </Section>
  );
};
