import {
  ApolloClient,
  BaseMutationOptions,
  PureQueryOptions,
  useApolloClient,
} from "@apollo/client";
import { useSearchActionMutation } from "apollo/hooks/mutations";
import { useRefetchOnConflict } from "apollo/hooks/mutations/utils";
import { useInvalidateQuery } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import { GET_AUCTION } from "core/apollo/graphql";
import {
  APP_PROVIDER_SEARCH,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  SEARCH_ACTION_ATTRIBUTE_WINNER,
  SEARCH_ACTION_CANCEL_ATTRIBUTION,
  SEARCH_ACTION_CANCEL_SEARCH,
  SEARCH_ACTION_MOVE_TO_AUTO_SEARCH,
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_START_MANUAL_SEARCH,
} from "core/consts";
import { mergeAuction } from "core/model/auctions";
import { useApolloEncryptionContext } from "core/model/patients/encryption/utils";
import { getErrorMessage } from "core/model/utils/errors";
import { getReferrer } from "core/model/utils/urls";
import { Auction, QueryProgress, SearchActionsWithHandlers } from "core/types";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useState } from "react";

export type SearchActionFunction = ({
  context,
  onCompleted,
  onError,
}: CallSearchActionProps) => Promise<Auction | null>;

type UseSearchActionReturnType = [
  SearchActionFunction,
  QueryProgress,
  () => void,
];

type CallSearchActionProps = {
  auction: Auction;
  context?: { [index: string]: any };
  onCompleted?: (args: Auction | null, client: ApolloClient<object>) => void;
  onError?: (error: unknown) => void;
};

export const dashboardRelatedActions = [
  SEARCH_ACTION_START_MANUAL_SEARCH,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_CANCEL_ATTRIBUTION,
  SEARCH_ACTION_MOVE_TO_AUTO_SEARCH,
  SEARCH_ACTION_CANCEL_SEARCH,
  SEARCH_ACTION_ATTRIBUTE_WINNER,
];

const shouldInvalidatePatientsNew = (actionType: SearchActionsWithHandlers) =>
  dashboardRelatedActions.includes(actionType);

const useGetRefetchQueries = ({
  refetchQueries,
}: {
  refetchQueries: (string | PureQueryOptions)[] | undefined;
}): BaseMutationOptions["refetchQueries"] | undefined => {
  const { app } = useEnvContext();

  const refetch: BaseMutationOptions["refetchQueries"] = refetchQueries ?? [];

  if (app === APP_PROVIDER_SEARCH) {
    refetch.push("getProviderSearchPatients");
  }

  return refetch?.length ? refetch : undefined;
};

const useSearchAction = ({
  actionType,
  refetchQueries,
}: {
  actionType: SearchActionsWithHandlers;
  refetchQueries?: (string | PureQueryOptions)[];
}): UseSearchActionReturnType => {
  const client = useApolloClient();
  const queriesToRefetch = useGetRefetchQueries({ refetchQueries });
  const [searchAction] = useSearchActionMutation({
    actionType,
    refetchQueries: queriesToRefetch,
  });
  const invalidate = useInvalidateQuery();

  const refetch = useRefetchOnConflict();
  const encryptionContext = useApolloEncryptionContext();

  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const callAction: ({
    auction,
    context,
    onCompleted,
    onError,
  }: CallSearchActionProps) => Promise<Auction | null> = useCallback(
    async ({
      onCompleted,
      context = {},
      onError,
      auction,
    }: CallSearchActionProps) => {
      setQueryProgress(QUERY_PROGRESS_PENDING);
      if (!auction) {
        const error = `Search passed to useSearchAction is missing for actionType ${actionType}`;
        console.error(error);
        setQueryProgress(QUERY_PROGRESS_FAILED);
        throw new Error(error);
      }

      try {
        const { data } = await searchAction(
          {
            auction_id: auction.id,
            context,
          },
          {
            auctionId: auction.id,
          },
        );
        if (!data?.auction) throw new Error("No auction returned");

        const searchActionResponse = mergeAuction(
          cloneDeep(auction),
          data?.auction,
        );

        if (shouldInvalidatePatientsNew(actionType)) {
          invalidate({ fieldName: "patientsNew" });
        }

        if (onCompleted) onCompleted(searchActionResponse, client);

        setQueryProgress(QUERY_PROGRESS_SUCCEED);

        return searchActionResponse;
      } catch (err) {
        setQueryProgress(QUERY_PROGRESS_FAILED);
        console.error(`${actionType} - `, getErrorMessage(err));
        refetch({
          error: err,
          variables: { auctionId: auction.id, ref: getReferrer() },
          context: { encryptionContext },
          query: GET_AUCTION,
        });
        if (onError) onError(err);
        return null;
      }
    },
    [searchAction],
  );

  return [
    callAction,
    queryProgress,
    () => {
      setQueryProgress(QUERY_PROGRESS_NOT_STARTED);
    },
  ];
};

export default useSearchAction;
