import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Section,
  SectionRow,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionSeven = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvMedicalForm.sectionSeven.title}
    >
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextAreaWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionSeven
              .remarksDescription
          }
          elementName="drv_remarks"
          marginOverride={margin(0)}
        />
      </SectionRow>
    </Section>
  );
};
