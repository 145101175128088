import { Section } from "dsl/ecosystems/PatientForms/components";
import { EarlyRehabMeasures } from "./EarlyRehabMeasures";
import { HospitalStay } from "./HospitalStay";
import { InsuredPersonDetails } from "./InsuredPersonDetails";
import { RehabAdmission } from "./RehabAdmission";

export const SectionOne = () => {
  return (
    <Section>
      <InsuredPersonDetails />
      <HospitalStay />
      <EarlyRehabMeasures />
      <RehabAdmission />
    </Section>
  );
};
