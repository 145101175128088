import Translations from "translations/types";

const REVERSE_SEARCH_DASHBOARD_PAGE_KEY = "reverse_search_dashboard" as const;

const REVERSE_SEARCH_DASHBOARD_STEPS = {
  SEARCH_TAB: "search_tab",
} as const;

export const REVERSE_SEARCH_DASHBOARD_PAGE = {
  key: REVERSE_SEARCH_DASHBOARD_PAGE_KEY,
  steps: {
    [REVERSE_SEARCH_DASHBOARD_STEPS.SEARCH_TAB]: {
      key: REVERSE_SEARCH_DASHBOARD_STEPS.SEARCH_TAB,
      title: (t: Translations) => t.tours.reverseSearch.stepA.title,
      intro: (t: Translations) => t.tours.reverseSearch.stepA.intro,
      step: 1,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  exitOnOverlayClick: false,
} as const;
