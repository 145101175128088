import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_NO, PREDICAMENT_STATE_YES } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { padding, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  Section,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionThree = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: PREDICAMENT_STATE_NO,
      value: PREDICAMENT_STATE_NO,
      label: translations.actions.no,
    },
    {
      id: PREDICAMENT_STATE_YES,
      value: PREDICAMENT_STATE_YES,
      label:
        translations.patientForms.drvGeneralForm.sectionThreeNew.yesIncludeForm,
    },
  ];

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionThreeNew.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionThreeNew.subtitle}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="care_person_necessary"
        label={
          translations.patientForms.drvGeneralForm.sectionThreeNew.subtitle
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
    </Section>
  );
};
