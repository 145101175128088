import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { REHAB_FOCUS } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { TextAreaWithLabel } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const RehabFocus = () => {
  const translations = useTranslations();

  const REHAB_FOCUS_ITEMS: RadioOptionV2[] = [
    {
      id: REHAB_FOCUS.HEART,
      value: REHAB_FOCUS.HEART,
      label: translations.patientForms.medicalForm.sectionFive.heart,
    },
    {
      id: REHAB_FOCUS.NEUROLOGICAL_DISEASE,
      value: REHAB_FOCUS.NEUROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.neurologicalDisease,
    },
    {
      id: REHAB_FOCUS.BLOOD_VESSELS,
      value: REHAB_FOCUS.BLOOD_VESSELS,
      label: translations.patientForms.medicalForm.sectionFive.bloodVessels,
    },
    {
      id: REHAB_FOCUS.ONCOLOGICAL_DISEASE,
      value: REHAB_FOCUS.ONCOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.oncologicalDiseases,
    },
    {
      id: REHAB_FOCUS.INFLAMMATORY_DISEASE,
      value: REHAB_FOCUS.INFLAMMATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.inflammatoryDiseases,
    },
    {
      id: REHAB_FOCUS.GYNAECOLOGICAL_DISEASE,
      value: REHAB_FOCUS.GYNAECOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive
          .gynaecologicalDiseases,
    },
    {
      id: REHAB_FOCUS.MUSCOLOSKELETAL_DISEASE,
      value: REHAB_FOCUS.MUSCOLOSKELETAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.musculoskeletalSystem,
    },
    {
      id: REHAB_FOCUS.SKIN_DISEASE,
      value: REHAB_FOCUS.SKIN_DISEASE,
      label: translations.patientForms.medicalForm.sectionFive.skinDiseases,
    },
    {
      id: REHAB_FOCUS.GASTROENTEROLOGICAL_DISEASE,
      value: REHAB_FOCUS.GASTROENTEROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive
          .gastroenterologicalDiseases,
    },
    {
      id: REHAB_FOCUS.PSYCHOSOMATIC_DISORDER,
      value: REHAB_FOCUS.PSYCHOSOMATIC_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionFive
          .psychosomaticDisorders,
    },
    {
      id: REHAB_FOCUS.ENDOCRINE_DISEASE,
      value: REHAB_FOCUS.ENDOCRINE_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.endocrineDiseases,
    },
    {
      id: REHAB_FOCUS.MENTAL_ILLNESS,
      value: REHAB_FOCUS.MENTAL_ILLNESS,
      label: translations.patientForms.medicalForm.sectionFive.mentalIllnesses,
    },
    {
      id: REHAB_FOCUS.RESPIRATORY_DISEASE,
      value: REHAB_FOCUS.RESPIRATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.respiratoryDiseases,
    },
    {
      id: REHAB_FOCUS.ADDICTIVE_DISORDER,
      value: REHAB_FOCUS.ADDICTIVE_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionFive.addictiveDisorders,
    },
    {
      id: REHAB_FOCUS.NEPHROLOGICAL_DISEASE,
      value: REHAB_FOCUS.NEPHROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.nephrologicalDiseases,
    },
    {
      id: REHAB_FOCUS.BLOOD_DISEASE,
      value: REHAB_FOCUS.BLOOD_DISEASE,
      label: translations.patientForms.medicalForm.sectionFive.bloodDiseases,
    },
    {
      id: REHAB_FOCUS.UROLOGICAL_DISEASE,
      value: REHAB_FOCUS.UROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.urologicalDiseases,
    },
    {
      id: REHAB_FOCUS.GERIATRICS,
      value: REHAB_FOCUS.GERIATRICS,
      label: translations.patientForms.medicalForm.sectionFive.geriatrics,
    },
    {
      id: REHAB_FOCUS.OTHER,
      value: REHAB_FOCUS.OTHER,
      label: translations.patientForms.medicalForm.sectionFive.others,
      subForm: (
        <TextAreaWithLabel
          elementName="rehab_focus_other_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          padding: padding(0, 0, 1),
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.medicalForm.sectionFive.rehabFocus}
      </Subheading>
      <RadioGroupV2
        elementName="rehab_focus"
        label={
          translations.patientForms.generalForm.sectionI
            .accessibilityGuardianType
        }
        options={REHAB_FOCUS_ITEMS}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
    </>
  );
};
