import {
  AT_HOME_SITUATION,
  HOME_CARE_SITUATION,
  LIVING_SITUATION_ALONE,
  LIVING_SITUATION_CAREHOME,
  LIVING_SITUATION_SHARED_FLAT,
  LIVING_SITUATION_WITH_FAMILY,
  REHAB_FORM_NAMES,
} from "core/consts";
import {
  OldRehabFormDataMapped,
  booleanToPredicamentState,
  stringDateToUnix,
  yesNoUnknownToPredicament,
} from "../../utils";
import {
  GeneralFormUniversalData,
  GeneralFormUniversalDataOld,
} from "./modelDefinition";

export const convertGeneralFormUniversalData = ({
  formDataOld,
}: {
  formDataOld: OldRehabFormDataMapped<
    typeof REHAB_FORM_NAMES.GENERAL_UNIVERSAL
  >;
}): GeneralFormUniversalData => {
  if (!formDataOld) return {};

  const newData: GeneralFormUniversalData = {};

  Object.keys(formDataOld).forEach((key) => {
    switch (key as keyof GeneralFormUniversalDataOld) {
      case "case_id": {
        newData.case_id = formDataOld.case_id || null;
        break;
      }

      case "communication_in_german_possible": {
        newData.communication_in_german_possible = yesNoUnknownToPredicament(
          formDataOld.communication_in_german_possible,
        );
        break;
      }

      case "communication_other_language": {
        newData.communication_other_language =
          formDataOld.communication_other_language || null;
        break;
      }

      case "domestic_situation_is_safe": {
        newData.domestic_situation_is_safe = yesNoUnknownToPredicament(
          formDataOld.domestic_situation_is_safe,
        );
        break;
      }

      case "general_practitioner_city": {
        newData.general_practitioner_city =
          formDataOld.general_practitioner_city || null;
        break;
      }

      case "general_practitioner_name": {
        newData.general_practitioner_name =
          formDataOld.general_practitioner_name || null;
        break;
      }

      case "general_practitioner_phone_number": {
        newData.general_practitioner_phone_number =
          formDataOld.general_practitioner_phone_number || null;
        break;
      }

      case "general_practitioner_street": {
        newData.general_practitioner_street =
          formDataOld.general_practitioner_street || null;
        break;
      }

      case "general_practitioner_zipcode": {
        newData.general_practitioner_zipcode =
          formDataOld.general_practitioner_zipcode || null;
        break;
      }

      case "hospital_address": {
        newData.hospital_address = formDataOld.hospital_address || null;
        newData.hospital_zipcode_city = formDataOld.hospital_address || null;
        break;
      }

      case "hospital_name": {
        newData.hospital_name = formDataOld.hospital_name || null;
        break;
      }

      case "insurance_company_name": {
        newData.insurance_company_name =
          formDataOld.insurance_company_name || null;
        break;
      }

      case "insurance_number": {
        newData.insurance_number = formDataOld.insurance_number || null;
        break;
      }

      case "living_lift": {
        newData.living_lift = yesNoUnknownToPredicament(
          formDataOld.living_lift,
        );
        break;
      }

      case "living_lift_description": {
        newData.living_lift_description =
          formDataOld.living_lift_description || null;
        break;
      }

      case "living_situation": {
        const livingSituation = formDataOld.living_situation;

        switch (livingSituation) {
          case LIVING_SITUATION_ALONE: {
            newData.living_situation = HOME_CARE_SITUATION.HOME;
            newData.living_situation_at_home = AT_HOME_SITUATION.ALONE;
            break;
          }
          case LIVING_SITUATION_WITH_FAMILY: {
            newData.living_situation = HOME_CARE_SITUATION.HOME;
            newData.living_situation_at_home = AT_HOME_SITUATION.RELATIVES;
            break;
          }
          case LIVING_SITUATION_CAREHOME: {
            newData.living_situation = HOME_CARE_SITUATION.CARE_HOME;
            break;
          }
          case LIVING_SITUATION_SHARED_FLAT: {
            newData.living_situation = HOME_CARE_SITUATION.ASSISTED_LIVING;
            break;
          }
          default: {
            newData.living_situation = null;
            break;
          }
        }
        break;
      }

      case "living_special": {
        newData.living_special = formDataOld.living_special || null;
        break;
      }

      case "patient_birthdate": {
        newData.patient_birthdate = stringDateToUnix({
          date: formDataOld.patient_birthdate,
        });
        break;
      }

      case "patient_city_zipcode": {
        newData.patient_city_zipcode = formDataOld.patient_city_zipcode || null;
        break;
      }

      case "patient_name": {
        newData.patient_name = formDataOld.patient_name || null;
        break;
      }

      case "patient_phone_number": {
        newData.patient_phone_number = formDataOld.patient_phone_number || null;
        break;
      }

      case "patient_street_housenumber": {
        newData.patient_street_housenumber =
          formDataOld.patient_street_housenumber || null;
        break;
      }

      case "prefered_clinic_contacted": {
        newData.prefered_clinic_contacted = booleanToPredicamentState(
          !!formDataOld.prefered_clinic_contacted,
        );
        newData.prefered_clinic_contacted_details =
          formDataOld.prefered_clinic_contacted || null;
        break;
      }

      case "reason_for_reha_clinic": {
        newData.reason_for_reha_clinic =
          formDataOld.reason_for_reha_clinic || null;
        break;
      }

      case "retirement_earning_capacity_company": {
        newData.retirement_earning_capacity_company =
          formDataOld.retirement_earning_capacity_company || null;
        break;
      }

      case "retirement_pension_applied": {
        newData.retirement_pension_applied = yesNoUnknownToPredicament(
          formDataOld.retirement_pension_applied,
        );
        break;
      }

      case "retirement_pension_company": {
        newData.retirement_pension_company =
          formDataOld.retirement_pension_company || null;
        break;
      }

      case "retirement_pension_earning_capacity_applied": {
        newData.retirement_pension_earning_capacity_applied =
          yesNoUnknownToPredicament(
            formDataOld.retirement_pension_earning_capacity_applied,
          );
        break;
      }

      case "social_worker_fax_number": {
        newData.social_worker_fax_number =
          formDataOld.social_worker_fax_number || null;
        break;
      }

      case "social_worker_full_name": {
        newData.social_worker_full_name =
          formDataOld.social_worker_full_name || null;
        break;
      }

      case "social_worker_phone_number": {
        newData.social_worker_phone_number =
          formDataOld.social_worker_phone_number || null;
        break;
      }

      // not needed fields
      case "barrier_free":
      case "barrier_free_description":
      case "care_level_higher_level_applied":
      case "carelevel":
      case "job_care_relatives":
      case "job_child_raising":
      case "job_mini_job":
      case "job_partial_retirement":
      case "job_pension_adjustment":
      case "pension_contribution":
      case "retirement_pension_earning_capacity":
      case "retirement_pension_received": {
        break;
      }

      default:
        break;
    }
  });

  return newData;
};
