import { useTranslations } from "translations";

import { padding } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  LARGE_INPUT_WIDTH,
  PatientFormSubheading,
  SectionRow,
  SMALL_INPUT_MIN_WIDTH,
} from "dsl/ecosystems/PatientForms/components";

export const HospitalStay = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading>
        {
          translations.patientForms.medicalForm.sectionOne
            .hospitalStayInformation
        }
      </PatientFormSubheading>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <DatePickerWithLabel
          bold
          elementName="admission_date"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          label={translations.patientForms.medicalForm.sectionOne.intakeDate}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
        <DatePickerWithLabel
          bold
          elementName="discharge_date"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          label={translations.patientForms.medicalForm.sectionOne.dischargeDate}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
    </>
  );
};
