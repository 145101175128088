import { margin } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SpecialRequirements = () => {
  const translations = useTranslations();

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {
          translations.patientForms.generalForm.sectionI
            .questionSpecialRequirements
        }
      </PatientFormSubheading>
      <TextAreaWithLabel
        elementName="reason_for_reha_clinic"
        label={
          translations.patientForms.generalForm.sectionI
            .labelSpecialRequirements
        }
        marginOverride={margin(0)}
      />
    </>
  );
};
