import { padding } from "ds_legacy/materials/metrics";
import {
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionEight = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionSeven.title}
    >
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionSix.doctorName}
          large
          elementName="doctor_in_charge_in_hospital"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.doctorFirstName}
          large
          elementName="doctor_in_charge_in_hospital_first_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionSeven.doctorPhone
          }
          elementName="doctor_in_charge_in_hospital_phone"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionSeven.doctorStreet
          }
          large
          elementName="street_house_number_doctor"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionSeven.doctorZipCode
          }
          elementName="postal_code_doctor"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionSeven.doctorCity
          }
          large
          elementName="town_doctor"
        />
      </SectionRow>
    </Section>
  );
};
