import { SelectOption } from "ds_legacy/components/SelectInput";
import {
  PatientFormSubheading,
  Section,
  SectionRow,
  SelectWithLabel,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { IK_NUMBER_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { useTranslations } from "translations";

const SELECT_OPTIONS: SelectOption[] = [
  {
    id: 1,
    label: "Baden-Württemberg Standort Karlsruhe",
    value: `Baden-Württemberg /76122 Karlsruhe`,
  },
  {
    id: 2,
    label: "Baden-Württemberg Standort Stuttgart",
    value: `Baden-Württemberg /70429 Stuttgart`,
  },
  {
    id: 3,
    label: "Bayern Süd Standort Landshut",
    value: `Bayern Süd /84024 Landshut`,
  },
  {
    id: 4,
    label: "Bayern Süd Standort München",
    value: `Bayern Süd /81729 München`,
  },
  {
    id: 5,
    label: "Berlin-Brandenburg Standort Berlin",
    value: `Berlin-Brandenburg /Knobelsdorffstr. 92 /14059 Berlin`,
  },
  {
    id: 6,
    label: "Berlin-Brandenburg Standort Frankfurt (Oder)",
    value: `Berlin-Brandenburg /Bertha-von-Suttner-Str. 1 /15236 Frankfurt (Oder)`,
  },
  {
    id: 7,
    label: "Braunschweig-Hannover Standort Braunschweig",
    value: `Braunschweig-Hannover /Kurt-Schumacher-Str. 20 /38102 Braunschweig`,
  },
  {
    id: 8,
    label: "Braunschweig-Hannover Standort Laatzen",
    value: `Braunschweig-Hannover /Lange Weihe 6 /30880 Laatzen`,
  },
  {
    id: 9,
    label: "Bund",
    value: `Bund /10704 Berlin`,
  },
  {
    id: 10,
    label: "Hessen",
    value: `Hessen /Städelstr. 28 /60596 Frankfurt am Main`,
  },
  {
    id: 11,
    label: "Knappschaft-Bahn-See Hauptverwaltung",
    value: `Knappschaft-Bahn-See /Pieperstr. 14-28 /44789 Bochum`,
  },
  {
    id: 12,
    label: "Mitteldeutschland Standort Erfurt",
    value: `Mitteldeutschland /Kranichfelder Str. 3 /99097 Erfurt`,
  },
  {
    id: 13,
    label: "Mitteldeutschland Standort Halle",
    value: `Mitteldeutschland /Paracelsusstr. 21 /06114 Halle`,
  },
  {
    id: 14,
    label: "Mitteldeutschland Standort Leipzig",
    value: `Mitteldeutschland /Georg-Schumann-Str. 146 /04159 Leipzig`,
  },
  {
    id: 15,
    label: "Nord Standort Hamburg",
    value: `Nord /Friedrich-Ebert-Damm 245 /22159 Hamburg`,
  },
  {
    id: 16,
    label: "Nord Standort Lübeck",
    value: `Nord /Ziegelstr. 150 /23556 Lübeck`,
  },
  {
    id: 17,
    label: "Nord Standort Neubrandenburg",
    value: `Nord /Platanenstr. 43 /17033 Neubrandenburg`,
  },
  {
    id: 18,
    label: "Nordbayern Standort Bayreuth",
    value: `Nordbayern /95440 Bayreuth`,
  },
  {
    id: 19,
    label: "Nordbayern Standort Würzburg",
    value: `Nordbayern /Friedenstr. 12/14 /97072 Würzburg`,
  },
  {
    id: 20,
    label: "Oldenburg-Bremen Geschäftsstelle Bremen",
    value: `Oldenburg-Bremen /Schwachhauser Heerstr. 32-34 /28209 Bremen`,
  },
  {
    id: 21,
    label: "Oldenburg-Bremen Hauptverwaltung Oldenburg",
    value: `Oldenburg-Bremen /Huntestr. 11 /26135 Oldenburg`,
  },
  {
    id: 22,
    label: "Rheinland",
    value: `Rheinland /40194 Düsseldorf`,
  },
  {
    id: 23,
    label: "Rheinland-Pfalz",
    value: `Rheinland-Pfalz /Eichendorffstr. 4-6 /67346 Speyer`,
  },
  {
    id: 24,
    label: "Saarland",
    value: `Saarland /Martin-Luther-Str. 2-4 /66111 Saarbrücken`,
  },
  {
    id: 25,
    label: "Schwaben",
    value: `Schwaben /Dieselstr. 9 /86154 Augsburg`,
  },
  {
    id: 26,
    label: "Westfalen",
    value: `Westfalen /Gartenstr. 194 /48147 Münster`,
  },
];

export const SectionSix = () => {
  const translations = useTranslations();

  return (
    <Section title={translations.patientForms.drvMedicalForm.sectionSix.title}>
      <SelectWithLabel
        bold
        large
        elementName="insurance_delivery_address"
        label={
          translations.patientForms.drvMedicalForm.sectionSix
            .addressPensionInsurance
        }
        options={SELECT_OPTIONS}
        wrapperSx={{ width: "100%" }}
      />
      <PatientFormSubheading>
        {
          translations.patientForms.drvMedicalForm.sectionSix
            .subtitleBankAccountInformation
        }
      </PatientFormSubheading>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionSix.accountHolder
          }
          large
          elementName="hospital_bank_holder"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionSix.bankName}
          large
          elementName="hospital_bank_name"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          mask="iban"
          label={translations.patientForms.drvMedicalForm.sectionSix.iban}
          large
          elementName="hospital_iban"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionSix.ikNumber}
          large
          elementName="hospital_ik_number"
          characterLimit={IK_NUMBER_CHARACTER_LIMIT}
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvMedicalForm.sectionSix.address}
          large
          elementName="hospital_billing_address"
        />
      </SectionRow>
    </Section>
  );
};
